import React, { useState } from "react"
import { Col, Row } from "react-bootstrap"

const Youthform = () =>{
    const [file, setFile] = useState("https://unper.ac.id/wp-content/plugins/tutor/assets/images/placeholder-.jpg");
    function handleChange(e) {
        console.log(e.target.files);
        setFile(URL.createObjectURL(e.target.files[0]));
    }
    return(
        <>
            <div className="form_section">
                <h4 className="f15">Add New Banner</h4>
                <form>
                    <Row>
                        <Col md={6} xs={12}>
                            <div className="form-group">
                                <label className="mb-1">Title</label>
                                <input className="form-control" type="text" placeholder="Enter Title" />
                            </div>
                            <div className="form-group mt-3">
                                <label className="mb-1">Description</label>
                                <textarea className="form-control" row={3} type="text" placeholder="Type here...." ></textarea>
                            </div>
                            <div className="form-group mt-3">
                                <label className="mb-1">URL</label>
                                <input className="form-control" type="text" placeholder="Enter Banner URL" />
                            </div>
                        </Col>
                        <Col md={6} xs={12}>
                        <label className="mb-1">Image</label>
                        <div className="multiupload active">
                            <div className="dotted_bor mt-0" id="bnone">
                                <div className="images_area" id="flexnone">
                                    <div className="upload_file">
                                        <img src={file} className="dropimg" />
                                        <div className="image_upload_botton">
                                            <input type="file" className="file_i" onChange={handleChange}/>
                                            <span className="">Select Image</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <small>Maximum upload file size: 40 MB.</small>
                        </div>
                        </Col>
                        <Col md={12}>
                            <div className="text-right mt-3">
                                <button className="cancelbtn px-5" id="bnone">Cancel</button>
                                <button className="buy-now px-5 mb-0" id="bnone">Submit</button>
                            </div>
                        </Col>
                    </Row>
                </form>
            </div>
        </>
    )
}
export default Youthform