import React, { useState } from "react";
import AdminHeader from "../../admincommen/header";
import Adminsidebar from "../../admincommen/sidebar";
import AdminFooter from "../../admincommen/footer";
import ShareholdersBanner from "./shareholders-banner";
import ShareholdersContent from "./shareholders-content";
import ShareholdersContentform from "./shareholderscontent-form";
import Shareholdersform from "./shareholdersbanner-form";

const Shareholdersadmin = () => {
    const [step, setClassStep] = useState(false); 
    const removeActive = () => {
        setClassStep(!step); 
    }
    const [step1, setClassStep1] = useState(true); 
    const addactbtn = () => {
        setClassStep1(!step1); 
    }

    const [step2, setClassStep2] = useState(true); 
    const shareremove = () => {
        setClassStep2(!step2); 
    }
    const [step3, setClassStp3] = useState(false); 
    const shareadd = () => {
        setClassStp3(!step3); 
    }
    
    return(
        <>
            <AdminHeader/>
            <Adminsidebar/>
            <div className='wrap'>
				<div className='content_wrap' id='dashboard'>
                    <h5 class="bundle-type-heading"><b>Investors</b></h5>
                    <div className="card_box">
                        <div className="d-flex align-items-center justify-between">
                            <h4 className="f15 mb-0">Shareholders</h4>
                            <a href="javascript:void(0);" className="mb-0 buy-now" id="bnone" onClick={()=>{addactbtn(); removeActive();}}>Add New Banner</a>
                        </div>
                        <div className="dashboard-full-wrap send-question-lect-content custom_tabs mt-2">
                            <div className={`banner_sec ${step1 ? "active" : "" }`}>
                                <ShareholdersBanner /> 
                            </div>
                        </div>
                        <div className={`banner_for_sec ${step ? "active" : "" }`}>
                            <Shareholdersform />
                        </div>
                    </div>
                    <div className="card_box mt-4">
                        <div className="d-flex align-items-center justify-between">
                            <h4 className="f15 mb-0">Shareholders Report</h4>    
                            <a href="javascript:void(0);" className="mb-0 buy-now" id="bnone" onClick={()=>{shareadd(); shareremove();}}>Add New</a>
                        </div>
                        <div className="dashboard-full-wrap send-question-lect-content custom_tabs mt-2">
                            <div className={`banner_sec ${step2 ? "active" : "" }`}>
                                 <ShareholdersContent/>
                            </div>
                            <div className={`banner_for_sec ${step3 ? "active" : "" }`}>
                                <ShareholdersContentform />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AdminFooter/>
        </>
    )
}
export default Shareholdersadmin