
import React, {useState} from 'react'
import Routerimg from "../../assets/image/dark-best.jpg"
import Newimage from "../../assets/image/light-best.jpg"
import Newimageone from "../../assets/image/img_one.png"
import Select from 'react-select';
import { Col, Container, Row } from "react-bootstrap";
import Header from "../common/header"
import Footer from "../common/footer"

const options = [
    { value: '1', label: 'Event Category' },
    { value: '2', label: 'Concert' },
    { value: '3', label: 'Concerts' },
    { value: '4', label: 'Culture Fest' },
    { value: '5', label: 'General' },
    { value: '6', label: 'Premiere' },
    { value: '7', label: 'Pulse Invasion' },
    { value: '8', label: 'Season of Surprises' },
    { value: '9', label: 'Seminars' },
    { value: '10', label: 'SOS 2018' },
    { value: '11', label: 'Sports' },
    { value: '12', label: 'This is Naija' },
    { value: '13', label: 'Yello Care 2018' },
    { value: '14', label: 'Yello Star' },
  ];
  const optionsone = [
    { value: '1', label: 'Event Location' },
    { value: '2', label: 'Abia' },
    { value: '3', label: 'Abuja' },
    { value: '4', label: 'Adamawa' },
    { value: '5', label: 'Akwa Ibom' },
    { value: '6', label: 'Anambra' },
    { value: '7', label: 'Bauchi' },
    { value: '8', label: 'Bayelsa' },
    { value: '9', label: 'Benue' },
    { value: '10', label: 'Borno' },
    { value: '11', label: 'Cross River' },
    { value: '12', label: 'Delta' },
    { value: '13', label: 'Ebonyi' },
    { value: '14', label: 'Edo' },
  ];

const Eventdetails = () => {
	const [selectedOption, setSelectedOption] = useState("1");

	return (
		<>
        <Header/>
            <section className='banner-home'>
				<div className="item bg_before">
					<div className="productimg relative">
						<img className="relative productimg" src={Routerimg} alt="Routerimg"/>
						<div className="pos_abs">
							<div className="container">
								<h1 className="mtn-banner__header mtn-h1 text_color">MTN Best of the Street: Celebrating Talent in Alimosho</h1>
                                <div className="pull-right event_bread">Events  <span className='color_yellow'>&gt;  </span></div>
							</div>
						</div>
					</div>                    
				</div>
            
                <section className="black_bg mtn7">
                <Container>
                    <div className="d-flex align-items-center justify-center">
                        <Select
                            defaultValue={selectedOption}
                            onChange={setSelectedOption}
                            options={options}
                            value={options.find(obj => obj.value === selectedOption)}
                            className="myselect"
                        />
                        <Select
                            defaultValue={selectedOption}
                            onChange={setSelectedOption}
                            options={optionsone}
                            value={optionsone.find(obj => obj.value === selectedOption)}
                            className="myselect"
                        />
                        <button className="buy-btn yellow_btn">QUICK SEARCH</button>
                    </div>
                </Container>
            </section></section>
            <div className='wrapper event_content'>
                <div className='row'>
                    <div className='col-sm-8'>
                        <div class="pull-right">
                            <div class="addthis_sharing_toolbox"></div>
                        </div>
                        <div class="icons">
                            <i class="fa fa-clock-o"></i>
                             :00 AM
                             <i class="fa fa-calendar"></i>
                              21st, Feb 2023
                              <i class="fa-solid fa-location-dot"></i> 
                        </div>
                    </div>
                </div>
            </div>
            <div className='wrapper event_content'>
                <div className='row'>
                    <div className='col-sm-9'>
                        <img src={Newimage} alt='Newimage' className='banner_img' />
                        <p>On February 21, 2022, we held the first edition of the MTN 
                            Best of the Street competition in Alimosho, Lagos State. The 
                            event took place at the Abesan Youth Centre in Abesan Estate, Ipaja,
                             and showcased some of the best talents in the community.</p>
                        <p>&nbsp;</p>
                        <p>More than 30 contestants competed for the top prize of
                             1.2 million naira, with the top five contestants receiving
                              various prizes. Ayotunde Sobowale, Precious Babalola, and Seun 
                              Elebimoyo were the top three contestants. Ayotunde Sobowale emerged
                               as the winner of the competition, impressing the judges with his talent
                                and charisma. His victory was well-deserved, and the audience cheered as he received his cash prize.</p>
                        <p>&nbsp;</p>
                        <p>
                            The event had a panel of judges comprising Kolawole Fashola,
                             Head of Business Affairs & Operations at The Temple Company; 
                             Yemisi Falaye, Head of Legal & Business Development at The Temple 
                             Company; and Emmanuella Nnadozie, Head of Marketing and Corporate Communications at Mavin
                              Global. Aisha Mumuni, Chief Digital and Strategy Officer (CDSO), and other MTN 
                            leadership team members also attended the event.
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            The night was not just about the competition, as top Nigerian
                             musicians Dbanj and Small Doctor performed at the event. Dbanj 
                             even had a competition with the contestants, giving 100,000 naira to the winner as well as cash to a few other talents.
                        </p>
                        <p>&nbsp;</p>
                        <p>
                             Overall, the MTN Best of the Street competition in Alimosho 
                             celebrated the talent and creativity found in Nigerian communities. 
                             It provided a platform for young people to showcase their skills and an 
                             opportunity for the audience to enjoy a night of entertainment. The event was a 
                             success, and we look forward to seeing more initiatives like this from MTN Nigeria in the future.
                        </p>
                        <button className='tab_last'>COMMENTS</button>
                    </div>
                </div>   
            </div>
            <section id='col-organic-c21' className='col-organic'>
                <header id='recommendations-header' className='recommend-col-header'>
                    <div className='container'>
                        <h2 className='recommendations-unit-title'>
                        Also on
                        <strong class="recommendations-forum">MTN EVENTS</strong> 
                        </h2>
                    </div>
                </header>
                <div className='recommendations-unit'>
                    <div className='recommendation-container'>
                        <button class="scroll-btn scroll-left" data-action="scroll-left" tabindex="-1">❮</button>
                    </div>
                    <div className='recommend-wrapper'>
                        <div className="recommend-posts" data-role="recommend-posts">
                            <div className='recommend-post post-0'>
                                <div className='recommend-post-header'>
                                    <div className='recommend-image-wrapper'>
                                        <img src={Newimageone} alt='Newimageone' className='banner_img' />
                                        <h3>MTN</h3>
                                    </div>
                                </div>
                            </div>
                        </div>  
                    </div>
                </div>
            </section>
        <Footer/>
		</>
	)
}
export default Eventdetails
