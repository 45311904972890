import React from "react";

const Star = ({ starId }) => {
  return (
    <span
      star-id={starId}
      role="button"
      className="star_rat"
      style={{background: "#ffffff", cursor: "pointer" }}
    >
      
    </span>
  );
};

// Create an array of 5: Array.from({length: 5}, (v,i) => i)
const Rating = props => {
  // Manages on Hover selection of a star
  const [selection, setSelection] = React.useState(0);

  // Manages rating selection
  const [rating, setRating] = React.useState(0);

  const hoverOver = event => {
    let starId = 0;
    if (event && event.target && event.target.getAttribute("star-id")) {
      starId = event.target.getAttribute("star-id");
    }
    setSelection(starId);
  };

  return (
    <div
      onMouseOver={hoverOver}
      onMouseOut={() => hoverOver(null)}
      onClick={event => setRating(event.target.getAttribute("star-id"))}
    >
      {Array.from({ length: 5 }, (v, i) => (
        <Star starId={i + 1} marked={selection ? selection > i : rating > i} />
      ))}
    </div>
  );
};

export default Rating;
