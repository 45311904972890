import React from 'react'
import Sampleuser from "../../../assets/image/sample_user.jpeg";

export default function AdminHeader() {
    
  return (
    <>
    <style>
        {
          `#frontpages header, #frontpages footer, #frontpages .black-layer, #frontpages .black-layer_m{
                display:none;
            }`
        }
    </style>
		<div className='header' id='headertop' style={{background:'#000000', padding:'0px 0px', position:'fixed', width:'100%', top:'0px', left:'0px', zIndex:'9999'}}>
			<div className="container-fluid">
				<div className='d-flex align-items-center justify-between'>
					<div className=''>
						<a href="#" className='dash'>
							<i class="fa fa-home" aria-hidden="true"></i> 
							<span>Dash</span>
						</a>
						<a href="#" className='dash'>
							<i class="fa fa-comment" aria-hidden="true"></i> 
							<span>0</span>
						</a>
					</div>
					<div className='white-text' >
						<ul className='profilul'>
							<li>
								<a href="#" className='white-text' id="logo_admin">
									Howdy, admin <img src={Sampleuser} alt="" className="sample_user" />
								</a>
							</li>
							<div className='profile_hover'>
								<div className='d-flex align-items-center'>
									<div id="prof_use">
										<img src={Sampleuser} alt="" className="sample_user" />
									</div>
									<div className='content_link'>
										<a href='/admin/profile/' className='submenu'>Profile</a>
										<a href='#' className='submenu'>Logout</a>
									</div>
								</div>
							</div>
						</ul>						
					</div>
				</div>
			</div>    
		</div>
	</>
  )
}

