import React, { useState } from "react";
import AdminHeader from "../../admincommen/header";
import Adminsidebar from "../../admincommen/sidebar";
import AdminFooter from "../../admincommen/footer";
import Financialbanner from "./financialbanner";
import Financialeditor from "./editor/financial-editor";
import Financialbannerform from "./financialbanner-form";
import Financialrepo from "./financialrepo";

const Financialreporting = () => {
    const [step, setClassStep] = useState(false); 
    const removeActive = () => {
        setClassStep(!step); 
    }
    const [step1, setClassStep1] = useState(true); 
    const addactbtn = () => {
        setClassStep1(!step1); 
    }
    
    return(
        <>
            <AdminHeader/>
            <Adminsidebar/>
            <div className='wrap'> 
				<div className='content_wrap' id='dashboard'>
                    <h5 class="bundle-type-heading"><b>Investors</b></h5>
                    <div className="card_box">
                        <div className="d-flex align-items-center justify-between">
                            <h4 className="f15 mb-0">Financial Reporting</h4>
                            <a href="javascript:void(0);" className="mb-0 buy-now" id="bnone" onClick={()=>{addactbtn(); removeActive();}}>Add New Banner</a>
                        </div>
                        <div className="dashboard-full-wrap send-question-lect-content custom_tabs mt-2">
                            <div className={`banner_sec ${step1 ? "active" : "" }`}>
                                <Financialbanner /> 
                            </div>
                        </div>
                        <div className={`banner_for_sec ${step ? "active" : "" }`}>
                            <Financialbannerform />
                        </div>
                    </div>
                    <div className="card_box mt-4">
                        <div className="d-flex align-items-center justify-between">
                            <h4 className="f15 mb-0">Financial Report</h4>    
                        </div>
                        <div className="mt-3">
                            <Financialrepo />
                        </div>
                        
                    </div>
                    <div className="card_box mt-4">
                        <h4 className="f15 mb-0">Add New Financial Report</h4>
                        <div className="mt-3">
                            <Financialeditor />
                        </div>
                        <div className="text-right mt-3">
                            <button className="cancelbtn px-5" id="bnone">Cancel</button>
                            <button className="buy-now px-5 mb-0" id="bnone">Save</button>
                        </div>
                    </div>
                </div>
            </div>
            <AdminFooter/>
        </>
    )
}
                        
export default Financialreporting