
import React, { useRef, useState } from 'react'
import Slider from 'react-slick'
import Routerimg from "../../assets/image/banner_img.jpg"
import Fivegbanner from "../../assets/image/banner_imgone.jpg"
import Amazonweb from "../../assets/image/banner_imgtwo.jpg"
import Meetingcopy from "../../assets/image/banner_imgthree.jpg"
import Bannerfour from "../../assets/image/banner_imgfour.jpg"

export default function PlanTypeCarousel() {
	const sliderRef = useRef()
	const [sliderIndex, serSliderIndex] = useState(0)
	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 2500,
	}

	return (
		<div>
			<Slider
				afterChange={(e) => {
					serSliderIndex(e)
				}}
				ref={sliderRef}
				arrows={null}
				{...settings}
			>
				<div className="item bg_before">
					<div className="productimg relative">
						<img className="relative productimg" src={Routerimg} alt="Routerimg"/>
						<div className="pos_abs">
							<div className="container">
								<h3 className="mtn-banner__header mtn-h1">ASAP 2022</h3>
								<button className="btn buy-now">Learn More <i class="fa fa-chevron-right ml-20" aria-hidden="true"></i></button>
							</div>
						</div>
					</div>
				</div>
				<div className="item bg_before">
					<div className="productimg relative">
						<img className="relative productimg" src={Fivegbanner} alt="Fivegbanner"/>
						<div className="pos_abs">
							<div className="container">
								<h3 className="mtn-banner__header mtn-h1">MTN Foundation ICT and Business Skills Training Phase 5</h3>
								<button className="btn buy-now">Read More <i class="fa fa-chevron-right ml-20" aria-hidden="true"></i></button>
							</div>
						</div>
					</div>
				</div>
				<div className="item bg_before">
					<div className="productimg relative">
						<img className="relative productimg" src={Amazonweb} alt="Amazonweb"/>
						<div className="pos_abs">
							<div className="container">
								<div className="mtn-strapline">MTN Foundation</div>
								<h3 className="mtn-banner__header mtn-h1">MTNF Scholarships: Beneficiaries</h3>
								<button className="btn buy-now">Read More <i class="fa fa-chevron-right ml-20" aria-hidden="true"></i></button>
							</div>
						</div>
					</div>
				</div>
				<div className="item bg_before">
					<div className="productimg relative">
						<img className="relative productimg" src={Meetingcopy} alt="Meetingcopy"/>
						<div className="pos_abs">
							<div className="container">
								<div className="mtn-strapline">Investor</div>
								<h3 className="mtn-banner__header mtn-h1">Y’ello Doctor Mobile Medical Intervention <br/>MTN 5G</h3>
								<button className="btn buy-now">Read More <i class="fa fa-chevron-right ml-20" aria-hidden="true"></i></button>
							</div>
						</div>
					</div>
				</div>
				<div className="item bg_before">
					<div className="productimg relative">
						<img className="relative productimg" src={Bannerfour} alt="Bannerfour"/>
						<div className="pos_abs">
							<div className="container">
								<div className="mtn-strapline">Turning Up business leaders for the future of Nigeria</div>
								<h3 className="mtn-banner__header mtn-h1">Youth Entrepreneurship Development Programme</h3>
								<button className="btn buy-now">Read More <i class="fa fa-chevron-right ml-20" aria-hidden="true"></i></button>
							</div>
						</div>
					</div>
				</div>
			</Slider>
		</div>
	)
}

