import React, { Fragment, useRef, useState } from 'react'
import Slider from 'react-slick'
import Artboardone from "../../assets/image/artboardone.jpg"
import Artboardtwo from "../../assets/image/artboardtwo.jpg"
import Artboardthree from "../../assets/image/artboardthree.jpg"
import Artboardfour from "../../assets/image/artboardfour.jpg"

export default function YouthCarousel() {
	const sliderRef = useRef()
	const [sliderIndex, serSliderIndex] = useState(0)
	const settings = {
		centerMode:true,
	    slidesToShow: 3,
	    slidesToScroll: 3,
	    dots: true,
	    infinite: true,
	    cssEase: 'linear',
	    centerPadding:"120px",
	    arrows: true,

		responsive: [
			{
				breakpoint: 764,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: true,
	    			centerPadding:"30px",
	    			arrows: false,
				},
			},
		],
	}
	return (
		<Fragment>
			<Slider
				afterChange={(e) => {
					serSliderIndex(e)
				}}
				ref={sliderRef}
				arrows={null}
				{...settings}
			>
				<div className="item">
					<div className="mtn-card mtn-card--shadow mtn-card--image mtn-card--link  mtn-card--cursor" id='h200'>
						<div className="mtn-card__content" id='pos_rel'>
							<a href='https://mpulseteen.mtn.ng/'>
								<div className="card-lern">
									<div className="mpulse-card-body-learn learn-on-go-content">            
										<span className='exp-icon-hd'>Screen class </span>
										<p className='mt-2 mb-4 youthpara'><span className='btn-text red_link'>The Nigeria prize for literature</span></p>
										<a href="https://mpulseteen.mtn.ng/" className='mtn-btn  mtn-btn--text-right-icon mtn-btn--skin-momo'>Explore more &gt;</a>
									</div>
								</div>
							</a>
						</div>
					</div>
				</div>{' '}
				<div className="item">
					<div className="mtn-card mtn-card--shadow mtn-card--image mtn-card--link  mtn-card--cursor" id='h200'>
						<div className="mtn-card__content" id='pos_rel'>
							<a href='https://mpulseteen.mtn.ng/'>
								<div className="card-lern">
									<div className="mpulse-card-body-learn2 learn-on-go-content">            
										<span className='exp-icon-hd'>9ijaKids </span>
										<p className='mt-2 mb-4 youthpara'><span className='btn-text red_link'>The Nigeria prize for literature</span></p>
										<a href="https://mpulseteen.mtn.ng/" className='mtn-btn  mtn-btn--text-right-icon mtn-btn--skin-momo'>Explore more &gt;</a>
									</div>
								</div>
							</a>
						</div>
					</div>
				</div>{' '}
				<div className="item">
					<div className="mtn-card mtn-card--shadow mtn-card--image mtn-card--link  mtn-card--cursor" id='h200'>
						<div className="mtn-card__content" id='pos_rel'>
							<a href='https://mpulseteen.mtn.ng/'>
								<div className="card-lern">
									<div className="mpulse-card-body-learn3 learn-on-go-content">            
										<span className='exp-icon-hd'>Pass ng </span>
										<p className='mt-2 mb-4 youthpara'><span className='btn-text red_link'>The Nigeria prize for literature</span></p>
										<a href="https://mpulseteen.mtn.ng/" className='mtn-btn  mtn-btn--text-right-icon mtn-btn--skin-momo'>Explore more &gt;</a>
									</div>
								</div>
							</a>
						</div>
					</div>
				</div>{' '}
				<div className="item">
					<div className="mtn-card mtn-card--shadow mtn-card--image mtn-card--link  mtn-card--cursor" id='h200'>
						<div className="mtn-card__content" id='pos_rel'>
							<a href='https://mpulseteen.mtn.ng/'>
								<div className="card-lern">
									<div className="mpulse-card-body-learn4 learn-on-go-content">            
										<span className='exp-icon-hd'>Vas digimobility </span>
										<p className='mt-2 mb-4 youthpara'><span className='btn-text red_link'>The Nigeria prize for literature</span></p>
										<a href="https://mpulseteen.mtn.ng/" className='mtn-btn  mtn-btn--text-right-icon mtn-btn--skin-momo'>Explore more &gt;</a>
									</div>
								</div>
							</a>
						</div>
					</div>
				</div>{' '}
			</Slider>
		</Fragment>
	)
}
