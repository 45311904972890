import React from 'react';
import './App.css';
import '../src/assets/css/Unificationstyle.css';
import '../src/assets/css/font-awesome.min.css';
import Home from './componets/home/home';
import Investor from './componets/investors/investor';
import Moreinvestor from './componets/investors/moreininvestors';
import Shareholders from './componets/investors/shareholders';
import Financialreporting from "./componets/investors/financialreporting"
import Announcement from "./componets/investors/announcements"
import Foundation from './componets/foundation/foundation';
import Events from './componets/event/events';
import Eventdetails from "./componets/event/event-details"
import Adminhome from './componets/admin/pages/home';
import DashboardAdmin from './componets/admin/pages/dashboard';
import Settings from './componets/admin/pages/settings';
import Investors from './componets/admin/pages/investors/investors';
import Financial from './componets/admin/pages/financial-reporting/financial';
import Moreininvestor from './componets/admin/pages/moreininvestors/moreininvestors';
import { Route, Link, Routes, BrowserRouter as Router } from 'react-router-dom'  
import Shareholdersadmin from './componets/admin/pages/shareholdersadmin/shareholdersadmin';
import Announcementadmin from './componets/admin/pages/announcements/announcement';
import Profile from './componets/admin/profile/profile';
import Login from './componets/admin/authe/login';
function App() {

  return (
    <>
    <Router forceRefresh={true}>
      <Routes>
        <Route exact path='/' element={< Home />}></Route>
        <Route exact path='/investor' element={< Investor />}></Route>
        <Route exact path='/investor/more-in-investors/' element={< Moreinvestor />}></Route>
        <Route exact path='/investor/shareholders/' element={< Shareholders />}></Route>
        <Route exact path='/investor/financial-reporting/' element={< Financialreporting />}></Route>
        <Route exact path='/investor/announcements/' element={< Announcement />}></Route>
        <Route exact path='/foundation/' element={< Foundation />}></Route>
        <Route exact path='/events/' element={< Events />}></Route>
        <Route exact path='/event/event-details' element={< Eventdetails />}></Route>
        {/* Admin Start */}        
        <Route exact path='/admin/' element={< DashboardAdmin />}></Route>
        <Route exact path='/admin/home/' element={< Adminhome />}></Route>
        <Route exact path='/admin/settings/' element={< Settings />}></Route>
        <Route exact path='/admin/investors/' element={< Investors />}></Route>
        <Route exact path='/admin/more-in-investors/' element={< Moreininvestor />}></Route>
        <Route exact path='/admin/shareholders/' element={< Shareholdersadmin />}></Route>
        <Route exact path='/admin/financial/' element={< Financial />}></Route>
        <Route exact path='/admin/announcement/' element={< Announcementadmin />}></Route>
        <Route exact path='/admin/profile/' element={< Profile />}></Route>
        <Route exact path='admin/login/' element={< Login/>}></Route>
      </Routes> 
    </Router>
    </>

  );
}

export default App;
