import React, {useState} from "react";
import EventCarousel from "./EventCarousel";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import Select from 'react-select';
import Eventone from "../../assets/image/eventone.jpg"
import Eventtwo from "../../assets/image/eventtwo.jpg"
import Eventthree from "../../assets/image/eventthree.jpg"
import Pastevent from "./pastevent";
import Fiveg from "../../assets/image/fiveg.jpg"

const options = [
    { value: '1', label: 'Event Category' },
    { value: '2', label: 'Concert' },
    { value: '3', label: 'Concerts' },
    { value: '4', label: 'Culture Fest' },
    { value: '5', label: 'General' },
    { value: '6', label: 'Premiere' },
    { value: '7', label: 'Pulse Invasion' },
    { value: '8', label: 'Season of Surprises' },
    { value: '9', label: 'Seminars' },
    { value: '10', label: 'SOS 2018' },
    { value: '11', label: 'Sports' },
    { value: '12', label: 'This is Naija' },
    { value: '13', label: 'Yello Care 2018' },
    { value: '14', label: 'Yello Star' },
  ];
  const optionsone = [
    { value: '1', label: 'Event Location' },
    { value: '2', label: 'Abia' },
    { value: '3', label: 'Abuja' },
    { value: '4', label: 'Adamawa' },
    { value: '5', label: 'Akwa Ibom' },
    { value: '6', label: 'Anambra' },
    { value: '7', label: 'Bauchi' },
    { value: '8', label: 'Bayelsa' },
    { value: '9', label: 'Benue' },
    { value: '10', label: 'Borno' },
    { value: '11', label: 'Cross River' },
    { value: '12', label: 'Delta' },
    { value: '13', label: 'Ebonyi' },
    { value: '14', label: 'Edo' },
  ];
const Events = () =>{
    const [selectedOption, setSelectedOption] = useState("1");
   
    return(
        <>
            <section className="banner-home">
                <div className="outer thu-ban">
                    <div id="big" className="owl-carousel owl-theme myul">
                        <EventCarousel />
                    </div>
                </div>
            </section>
            <section className="black_bg mtn7">
                <Container>
                    <div className="d-flex align-items-center justify-center">
                        <Select
                            defaultValue={selectedOption}
                            onChange={setSelectedOption}
                            options={options}
                            value={options.find(obj => obj.value === selectedOption)}
                            className="myselect"
                        />
                        <Select
                            defaultValue={selectedOption}
                            onChange={setSelectedOption}
                            options={optionsone}
                            value={optionsone.find(obj => obj.value === selectedOption)}
                            className="myselect"
                        />
                        <button className="buy-btn yellow_btn">QUICK SEARCH</button>
                    </div>
                </Container>
            </section>
            <section>
                <Container>
                    <h4 class="mb40 mtn-title--heading text-center d-block float_none">Most Recent Events</h4>
                    <Row>
                        <Col md={4} sm={6} xs={12} >
                            <div className="item">
                            <a href='/event/event-details' className='red_link white-text'>
                                <div className="mtn-card mtn-card--shadow mtn-card--image mtn-card--link  mtn-card--cursor">
                                    
                                        <img className="personal" src={Eventone} alt="Eventone"/>
                                        <div className="mtn-card__content">
                                            <h3 className="mtn-card__header mtn-h2  d-flex align-items-center justify-between w-100"> MTN Best of the Street: Celebrating Talent in Alimosho
                                            </h3>
                                            <div className="timeplace">
                                                <small>
                                                    <i className="fa-solid fa-map-marker"></i> Benue   
                                                    <i className="fa-solid fa-calendar ml32"></i> 21st, Feb 2023	
                                                </small>
                                            </div>
                                        </div>
                                </div>
                                    </a>
                            </div>{' '}	
                        </Col>
                        <Col md={4} sm={6} xs={12} >
                            <div className="item">
                            <a href='/event/event-details/' className='red_link white-text'>
                                <div className="mtn-card mtn-card--shadow mtn-card--image mtn-card--link  mtn-card--cursor">
                                    
                                        <img className="personal" src={Eventtwo} alt="Eventtwo"/>
                                        <div className="mtn-card__content">
                                            <h3 class="mtn-card__header mtn-h2  d-flex align-items-center justify-between w-100"> Tecno Debuts it’s 5G enabled Smartphone
                                            </h3>
                                            <div className="timeplace">
                                                <small>
                                                    <i className="fa-solid fa-map-marker"></i> Adamawa   
                                                    <i className="fa-solid fa-calendar ml32"></i> 13th, March 2023							
                                                </small>
                                            </div>
                                        </div>
                                </div>
                                    </a>
                            </div>{' '}	
                        </Col>
                        <Col md={4} sm={6} xs={12} >
                            <div className="item">
                            <a href='https://events.mtn.ng/events/mpulse-spelling-bee-winner-emerges-as-ceo-for-a-day/' className='red_link white-text'>
                                <div className="mtn-card mtn-card--shadow mtn-card--image mtn-card--link  mtn-card--cursor">
                                    
                                        <img className="personal" src={Eventthree} alt="Eventthree"/>
                                        <div className="mtn-card__content">
                                            <h3 class="mtn-card__header mtn-h2  d-flex align-items-center justify-between w-100"> mPulse Spelling Bee Winner emerges as CEO for a Day
                                            </h3>
                                            <div className="timeplace">
                                                <small>
                                                    <i className="fa-solid fa-map-marker"></i> Delta   
                                                    <i className="fa-solid fa-calendar ml32"></i> 2nd, Apr 2023							
                                                </small>
                                            </div>
                                        </div>
                                </div>
                                    </a>
                            </div>{' '}	
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="black_bg">
                <Container>
                    <div className="event_tab" id="event_tab">
                        <Tabs
                            defaultActiveKey="pastevent"
                            id="uncontrolled-tab-example"
                        >
                            <Tab eventKey="upcomming" title="Upcomming Events">
                                {/* <Upcommingevent /> */}
                                <div className="text-center">
                                    <a href="#/" className="btn-buy viewallbtn ">View All Events</a>
                                </div>
                            </Tab>
                            <Tab eventKey="pastevent" title="Past Events">
                                <Pastevent />
                                <div className="text-center">
                                    <a href="#/" className="btn-buy viewallbtn ">View All Events</a>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </Container>
            </section>
            <section>
                <Container>
                    <div className="text-center">
                        <span className="pancil"></span>
                        <h4 class="mtn-title--heading text-center d-block float_none">MTN In The News</h4>
                        <p className="mb40">Read the latest news from MTN Nigeria.</p>
                        <div className="home_blog_box">
                            <Row>
                                <Col md={4} xs={12}>
                                    <div className="position-relative h-100">
                                        <div class="date date_abs">11th, Dec 2019</div>
                                        <img src={Fiveg} alt="Fiveg" className="fiveg" />
                                    </div>
                                </Col>
                                <Col md={8} xs={12}>
                                    <div className="cont_fiveg">
                                        <a href="/#" className="main_title">Welcome To 5G</a>
                                        <p>We got to experience the future, LIVE in Abuja, as we unveiled the first official 5G Demo in Nigeria, in partnership with Huawei.</p>
                                        <p>This event was made possible because of the strong collaboration between the Ministry of Communications Technology and Digital Economy, the Nigerian Communications Commission, MTN and Huawei (Abuja), ZTE (Calabar) and Ericsson (Lagos.) This immersive demo and experience will travel across three Nigerian cities to showcase the capabilities of 5G and its potential to enable economic growth, and social inclusion.</p>
                                        <a href="#/" className="btn-buy readbtn mt-0">Read More</a>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Container>
            </section>
            <section className="gray_bg_section">
                <Container>
                    <form>
                        <div className="d-flex align-items-center justify-center newsletter">
                            <div>NEWSLETTER</div>
                            <div className="position-relative mid">
                                <input type="text" name="email" required="required" value="" className="form-control input-lg" placeholder="Enter Your Email Address"/>
                            </div>
                            <button className="buy-btn yellow_btn">SUBSCRIBE</button>
                        </div>
                    </form>
                </Container>
            </section>
        </>
    )
}
export default Events