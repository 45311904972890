import React, { useState } from "react";
import AdminHeader from "../../admincommen/header";
import Adminsidebar from "../../admincommen/sidebar";
import AdminFooter from "../../admincommen/footer";
import JsonData from './investors.json';
import Investorsform from "./investors-form";
import Annual from "./annual";
import Investoreditor from "./editor/investor-editor";


const Investors = () => {
    const [step, setClassStep] = useState(false); 
    const removeActive = () => {
        setClassStep(!step); 
    }
    const [step1, setClassStep1] = useState(true); 
    const addactbtn = () => {
        setClassStep1(!step1); 
    }

    const [annual, setAnnual] = useState(false); 
    const annualremove = () => {
        setAnnual(!annual); 
    }
    const [annual1, setAnnual1] = useState(true); 
    const annualbtn = () => {
        setAnnual1(!annual1); 
    }
    const DisplayData=JsonData.map(
        (info)=>{
            return(
                <tr>
                    <td>{info.title}</td>
                    <td><img src={info.imgpath} alt='info.imgpath' className='tbimg' /></td>
                    <td>
                        <a href="javascript:void(0);" className='anc_edt'>Edit</a> 
                        <a href="javascript:void(0);" className='anc_del'>Delete</a>
                    </td>
                </tr>
            )
        }
    )
    return(
        <>
            <AdminHeader/>
            <Adminsidebar/>
            <div className='wrap'>
				<div className='content_wrap' id='dashboard'>
                    <h5 class="bundle-type-heading"><b>Investors</b></h5>
                    <div className="card_box">
                        <div className="d-flex align-items-center justify-between">
                            <h4 className="f15 mb-0">Banner</h4>
                            <a href="javascript:void(0);" className="mb-0 buy-now" id="bnone" onClick={()=>{addactbtn(); removeActive();}}>Add New Banner</a>
                        </div>
                        <div className="dashboard-full-wrap send-question-lect-content custom_tabs mt-2">
                            <div className={`banner_sec ${step1 ? "active" : "" }`}>
                                <table className='striped custom_table w-100'>
                                    <thead>
                                        <tr>
                                            <th>Title</th>
                                            <th>Image</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>                
                                        {DisplayData}
                                    </tbody>
                                </table>  
                            </div>
                            <div className={`banner_for_sec ${step ? "active" : "" }`}>
                                <Investorsform />
                            </div>
                        </div>
                    </div>
                    <div className="card_box mt-4">
                        <div className="d-flex align-items-center justify-between">
                            <h4 className="f15 mb-0">Annual Report</h4>    
                            <a href="javascript:void(0);" className="mb-0 buy-now" id="bnone" onClick={()=>{annualbtn(); annualremove();}}>Add New</a>
                        </div>
                        <div className={`banner_sec ${annual1 ? "active" : "" }`}>
                            <Annual /> 
                        </div>
                        <div className={`banner_for_sec ${annual ? "active" : "" }`}>
                            <Investorsform />
                        </div>
                    </div>
                    <div className="card_box mt-4">
                        <h4 className="f15 mb-0">Content</h4>
                        <div className="mt-3">
                            <Investoreditor />
                        </div>
                        <div className="text-right mt-3">
                            <button className="cancelbtn px-5" id="bnone">Cancel</button>
                            <button className="buy-now px-5 mb-0" id="bnone">Save</button>
                        </div>
                    </div>
                </div>
            </div>
            <AdminFooter/>
        </>
    )
}
export default Investors