import React, { Fragment, useRef, useState } from 'react'
import Slider from 'react-slick'
import Fourgbanner from "../../assets/image/fourg-banner.jpg"
import Pulse from "../../assets/image/pulse.jpg"
import Bannerxtra from "../../assets/image/banner-home-xtravalue.jpg"
import Travel from "../../assets/image/travel.jpg"
import Siftinto from "../../assets/image/shift-into-turbo-web.jpg"
import Newbroadband from "../../assets/image/new-broadband-look.jpeg"
import Bannerdata from "../../assets/image/banner-data.jpg"

export default function PersonalCarousel() {
	const sliderRef = useRef()
	const [sliderIndex, serSliderIndex] = useState(0)
	const settings = {
		centerMode:true,
	    slidesToShow: 3,
	    slidesToScroll: 3,
	    dots: true,
	    infinite: true,
	    cssEase: 'linear',
	    centerPadding:"120px",
	    arrows: true,

		responsive: [
			{
				breakpoint: 764,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: true,
	    			centerPadding:"30px",
	    			arrows: false,
				},
			},
		],
	}
	return (
		<Fragment>
			<Slider
				afterChange={(e) => {
					serSliderIndex(e)
				}}
				ref={sliderRef}
				arrows={null}
				{...settings}
			>
				<div className="item">
					<div className="mtn-card mtn-card--shadow mtn-card--image mtn-card--link  mtn-card--cursor" id='h200'>
						<div className="mtn-card__content" id='pos_rel'>
							<div className="exp-box">
								<a className="ex-box-link blue-1" href="https://selfservice.mtn.ng/">
									<span className="exp-icon"> 
										<img src="https://selfservice.thestorywallcafe.com/html/selfservice/images//recharge.png" alt=""/> 
									</span>
									<span className="exp-icon-hd"> Airtime <br/> Recharge </span>
								</a>
							</div>
						</div>
					</div>
				</div>{' '}
				<div className="item">
					<div className="mtn-card mtn-card--shadow mtn-card--image mtn-card--link  mtn-card--cursor" id='h200'>
						<div className="mtn-card__content" id='pos_rel'>
							<div className="exp-box">
								<a className="ex-box-link org-2" href="https://selfservice.mtn.ng/">
									<span className="exp-icon"> 
										<img src="https://selfservice.thestorywallcafe.com/html/selfservice/images/bundles.png" alt=""/> 
									</span>
									<span className="exp-icon-hd"> Buy <br/> Bundles </span>
								</a>
							</div>
						</div>
					</div>
				</div>{' '}
				<div className="item">
					<div className="mtn-card mtn-card--shadow mtn-card--image mtn-card--link  mtn-card--cursor" id='h200'>
						<div className="mtn-card__content" id='pos_rel'>
							<div className="exp-box">
								<a className="ex-box-link gr-1" href="https://selfservice.mtn.ng/">
									<span className="exp-icon"> 
										<img src="https://selfservice.thestorywallcafe.com/html/selfservice/images/share-airtime.png" alt=""/> 
									</span>
									<span className="exp-icon-hd"> Share <br/> data/airtime </span>
								</a>
							</div>
						</div>
					</div>
				</div>{' '}
				<div className="item">
					<div className="mtn-card mtn-card--shadow mtn-card--image mtn-card--link  mtn-card--cursor" id='h200'>
						<div className="mtn-card__content" id='pos_rel'>
							<div className="exp-box">
								<a href="https://selfservice.mtn.ng/" className="ex-box-link gr-2">
									<span className="exp-icon"> 
										<img src="https://selfservice.thestorywallcafe.com/html/selfservice/images/icons-borrow-airtime.png" alt=""/> 
									</span>
									<span className="exp-icon-hd"> Borrow <br/> data/airtime </span>
								</a>
							</div>
						</div>
					</div>
				</div>{' '}
				<div className="item">
					<div className="mtn-card mtn-card--shadow mtn-card--image mtn-card--link  mtn-card--cursor" id='h200'>
						<div className="mtn-card__content" id='pos_rel'>
							<div className="exp-box">
								<a className="ex-box-link org-2" href="https://selfservice.mtn.ng/">
									<span className="exp-icon"> 
										<img src="https://selfservice.thestorywallcafe.com/html/selfservice/images/bundles.png" alt=""/> 
									</span>
									<span className="exp-icon-hd"> Buy <br/> Bundles </span>
								</a>
							</div>
						</div>
					</div>
				</div>{' '}
			</Slider>
		</Fragment>
	)
}
