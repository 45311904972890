
import React, { useRef, useState } from 'react'
import Slider from 'react-slick'
import Routerimg from "../../assets/image/dark-mtnceo.jpg"
import Fivegbanner from "../../assets/image/tecno.jpg"
import Amazonweb from "../../assets/image/midap.jpg"
import Meetingcopy from "../../assets/image/afi-event.jpg"
import Bannerfour from "../../assets/image/dark-wkanda-forever.jpg"

export default function EventCarousel() {
	const sliderRef = useRef()
	const [sliderIndex, serSliderIndex] = useState(0)
	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: false,
		autoplaySpeed: 2500,
	}

	return (
		<div>
			<Slider
				afterChange={(e) => {
					serSliderIndex(e)
				}}
				ref={sliderRef}
				arrows={null}
				{...settings}
			>
				<div className="item bg_before">
					<div className="productimg relative">
						<img className="relative productimg" src={Routerimg} alt="Routerimg"/>
						<div className="pos_abs text-center">
							<div className="container">
                                <h2 className="events_date">1st, Feb 2023</h2>
								<h3 className="mtn-banner__header mtn-h1">mPulse Spelling Bee Winner emerges as CEO for a Day</h3>
                                <ul className="countdown" id="countdown">
                                    <li>
                                        <p className="days_ref">days</p>
                                        <span className="days">00</span>
                                    </li>
                                    <li className="seperator">:</li>
                                    <li>
                                        <p className="hours_ref">hours</p>
                                        <span className="hours">00</span>
                                    </li>
                                    <li className="seperator">:</li>
                                    <li>
                                        <p className="minutes_ref">minutes</p>
                                        <span className="minutes">00</span>

                                    </li>
                                    <li className="seperator">:</li>
                                    <li>
                                        <p className="seconds_ref">seconds</p>
                                        <span className="seconds">00</span>
                                    </li>
                                </ul>
								<button className="btn buy-now">View More <i className="fa fa-chevron-right ml-20" aria-hidden="true"></i></button>
							</div>
						</div>
					</div>
				</div>
				<div className="item bg_before">
					<div className="productimg relative">
						<img className="relative productimg" src={Fivegbanner} alt="Fivegbanner"/>
						<div className="pos_abs">
                        <div className="pos_abs text-center">
							<div className="container">
                                <h2 className="events_date">11th, Feb 2023</h2>
								<h3 className="mtn-banner__header mtn-h1">TECNO DEBUTS IT’S 5G ENABLED SMARTPHONE</h3>
                                <ul className="countdown" id="countdown">
                                    <li>
                                        <p className="days_ref">days</p>
                                        <span className="days">00</span>
                                    </li>
                                    <li className="seperator">:</li>
                                    <li>
                                        <p className="hours_ref">hours</p>
                                        <span className="hours">00</span>
                                    </li>
                                    <li className="seperator">:</li>
                                    <li>
                                        <p className="minutes_ref">minutes</p>
                                        <span className="minutes">00</span>

                                    </li>
                                    <li className="seperator">:</li>
                                    <li>
                                        <p className="seconds_ref">seconds</p>
                                        <span className="seconds">00</span>
                                    </li>
                                </ul>
								<button className="btn buy-now">View More <i className="fa fa-chevron-right ml-20" aria-hidden="true"></i></button>
							</div>
						</div>
						</div>
					</div>
				</div>
				<div className="item bg_before">
					<div className="productimg relative">
						<img className="relative productimg" src={Amazonweb} alt="Amazonweb"/>
						<div className="pos_abs text-center">
							<div className="container">
                                <h2 className="events_date">29th, Jan 2023</h2>
								<h3 className="mtn-banner__header mtn-h1">MIPAD100 PARTNERS WITH MTN FOR ANNUAL LAUNCH</h3>
                                <ul className="countdown" id="countdown">
                                    <li>
                                        <p className="days_ref">days</p>
                                        <span className="days">00</span>
                                    </li>
                                    <li className="seperator">:</li>
                                    <li>
                                        <p className="hours_ref">hours</p>
                                        <span className="hours">00</span>
                                    </li>
                                    <li className="seperator">:</li>
                                    <li>
                                        <p className="minutes_ref">minutes</p>
                                        <span className="minutes">00</span>

                                    </li>
                                    <li className="seperator">:</li>
                                    <li>
                                        <p className="seconds_ref">seconds</p>
                                        <span className="seconds">00</span>
                                    </li>
                                </ul>
								<button className="btn buy-now">View More <i className="fa fa-chevron-right ml-20" aria-hidden="true"></i></button>
							</div>
						</div>
					</div>
				</div>
				<div className="item bg_before">
					<div className="productimg relative">
						<img className="relative productimg" src={Meetingcopy} alt="Meetingcopy"/>
						<div className="pos_abs text-center">
							<div className="container">
                                <h2 className="events_date">13th, Nov 2022</h2>
								<h3 className="mtn-banner__header mtn-h1">AFRIFF’S SEVEN DAYS OF ENTERTAINMENT, MOVIES AND EDUCATIONAL CONTENT</h3>
                                <ul className="countdown" id="countdown">
                                    <li>
                                        <p className="days_ref">days</p>
                                        <span className="days">00</span>
                                    </li>
                                    <li className="seperator">:</li>
                                    <li>
                                        <p className="hours_ref">hours</p>
                                        <span className="hours">00</span>
                                    </li>
                                    <li className="seperator">:</li>
                                    <li>
                                        <p className="minutes_ref">minutes</p>
                                        <span className="minutes">00</span>

                                    </li>
                                    <li className="seperator">:</li>
                                    <li>
                                        <p className="seconds_ref">seconds</p>
                                        <span className="seconds">00</span>
                                    </li>
                                </ul>
								<button className="btn buy-now">View More <i className="fa fa-chevron-right ml-20" aria-hidden="true"></i></button>
							</div>
						</div>
					</div>
				</div>
				<div className="item bg_before">
					<div className="productimg relative">
						<img className="relative productimg" src={Bannerfour} alt="Bannerfour"/>
						<div className="pos_abs text-center">
							<div className="container">
                                <h2 className="events_date">6th, Nov 2022</h2>
								<h3 className="mtn-banner__header mtn-h1">BLACK PANTHER IN LAGOS</h3>
                                <ul className="countdown" id="countdown">
                                    <li>
                                        <p className="days_ref">days</p>
                                        <span className="days">00</span>
                                    </li>
                                    <li className="seperator">:</li>
                                    <li>
                                        <p className="hours_ref">hours</p>
                                        <span className="hours">00</span>
                                    </li>
                                    <li className="seperator">:</li>
                                    <li>
                                        <p className="minutes_ref">minutes</p>
                                        <span className="minutes">00</span>

                                    </li>
                                    <li className="seperator">:</li>
                                    <li>
                                        <p className="seconds_ref">seconds</p>
                                        <span className="seconds">00</span>
                                    </li>
                                </ul>
								<button className="btn buy-now">View More <i className="fa fa-chevron-right ml-20" aria-hidden="true"></i></button>
							</div>
						</div>
					</div>
				</div>
			</Slider>
		</div>
	)
}

