import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Invmore from "../../assets/image/Terms.jpg";
import Gray_up from "../../assets/image/gray_up.png";
import Gray_up_m from "../../assets/image/gray_up_m.png";

const Announcement = () => {
  return (
    <>
      <section className="banner-home">
        <div className="outer thu-ban">
          <div id="big" className="owl-carousel owl-theme">
            <div className="page_banner relative bg_before">
              <img src={Invmore} alt="Invmore" className="ban_img productimg" />
              <div className="pos_abs">
                <div className="item">
                  <Container>
                    <div className="mtn-strapline">More in investors</div>
                    <h1 className="mtn-banner__header mtn-h1">Announcement</h1>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <img src={Gray_up} alt="Gray_up" className="gray_up mtn46" />
      <img src={Gray_up_m} alt="Gray_up_m" className="gray_up_m" />
      <section className="mtn-bg--white mnegt-27" id="df">
        <Container>
          <Row>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Nigeria Board Meeting Resolutions - April 2023
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 299KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Nigeria AGM Resolutions - April 2023
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 999 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        Notice of Board Meeting and Closed Period - March 2023
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 322 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Nigeria Notice of Annual General Meeting 2023
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 437 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Nigeria 2023 AGM Explanatory Notes
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 461 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        Notification of the Completion of Allotment of Incentive
                        Shares to Qualified Shareholders
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 452 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Nigeria raises N125 billion via Commercial Paper
                        Issuance
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 332 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Nigeria Final Dividend Information FY 2022
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 343 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Nigeria Board Meeting Resolutions January 2023
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 341 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        FAQ on the Upcoming Allotment of Incentive Shares
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 556 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN - Upcoming Allotment of Incentive Shares
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 342 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        Notice of Board Meeting and Closed Period Dec 2022
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 416 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Nigeria EGM Resolutions December 2022
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 507 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN named the Most Compliant Listed Company on the NGX
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 422 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">MTN Nigeria 2022 EGM Proxy Form</h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 559 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">MTN Nigeria 2022 EGM Notice</h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 442 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Nigeria 2022 EGM Explanatory Memo
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 410 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        Appointment of Obiageli Ugboma as Chief Risk and
                        Compliance Officer
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 471 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Nigeria Board Meeting Resolutions October 2022
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 339 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        EIB backs MTN Nigeria with €100M for high-speed network
                        expansion
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 528 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Nigeria - Completes Issuance of N115 Billion Series
                        1 Dual-Tranche Fixed Rate Bonds
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 481 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        Registration of New Bond Issuance Programme and Series 1
                        Issuance
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 377 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Nigeria - Issuance of up to N23bn Series 3
                        Commercial Paper Notes
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 302 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Kicks Off 5G Pilot in Nigeria
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 444 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Nigeria Dividend Information H1 2022
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 283 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">MTN Nigeria Ratings Review</h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 482 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Nigeria Registration of Bond Issuance Programme
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 371 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Nigeria Publication on Tax Remittance
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 414 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        Notice of Board Meeting and Closed Period June 2022.
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 398 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        Notice of Board Meeting and Closed Period June 2022.
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 398 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        Appointment of Mr. Mazen Mroue to the Board of MTN
                        Nigeria
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 463 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MoMo Payment Service Bank commences Commercial
                        Operations
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 503 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Nigeria Shareholder Engagement Policy
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 288 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Nigeria Board Meeting Resolutions April 2022
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 272 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        Transcript of MTN Group call on NIN registration update
                        in Nigeria
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 4 MB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Nigeria AGM Resolutions April 2022
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 483 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Nigeria - Issuance of N127 billion Series 1 and 2
                        Commercial Paper
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 347 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        Resignation of Mr. Cyril Ilok - Chief Risk and
                        Compliance Officer
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 333 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        Central Bank of Nigeria grants Final Approval for MoMo
                        PSB to Commence Operations
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 329 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Statement on implementation of NCC directive on
                        NIN-SIM linkage
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 577 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Nigeria - Proposed issuance of up to N150 billion
                        Series 1 and 2 Commercial Paper
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 281 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Nigeria named best Tax Compliant Company in Nigeria
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 523 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">Notification of Board Change</h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 240 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        Notice of Board Meeting and Closed Period March 2022
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 298 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">Media Report Clarification</h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 390 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Nigeria’s Response to News Alleging Planned
                        Industrial Action
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 311 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        Results of the Series 1 Offer for Sale of MTN Nigeria
                        Ordinary Shares
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 869 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Nigeria - Nigerian Code of Corporate Governance
                        Report 2021
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 3.24 MB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Nigeria Offer for Sale Allotment
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 294 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Nigeria Board Meeting Resolutions January 2022
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 259 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Nigeria Final Dividend Information FY 2021
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 280 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Nigeria Clarification of Rumour
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 245 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        Notice of Board Meeting and Closed Period Dec 2021
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 252 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">Notification of Share Dealing</h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 264 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN successfully bids for 3.5GHz Spectrum for 5G Rollout
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 423 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        Launch of Public Offer of MTN Nigeria Ordinary Shares
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 416 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN launches Equity Bookbuild ahead of its Retail Offer
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 219 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Nigeria obtains Approval in Principle for MoMo
                        Payment Service Bank
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 317 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Nigeria Series II Bond Programme
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 509 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        Announcement of intention to offer MTN Nigeria ordinary
                        shares for sale
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 285 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Nigeria Board Meeting Resolutions October 2021
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 263 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Nigeria N89.999 Billion Series II Bond Issuance
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 265 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        Notice of Board meeting and Closed Period Sept 2021
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 328 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        Proposed MTN Nigeria N89.999 billion Bond Issuance
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 298 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Nigeria Communications PLC Licence Renewals
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 351 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        Clarification on Alleged Indebtedness to the Federal
                        Government of Nigeria
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 416 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Nigeria - Board Meeting Resolutions - July 2021
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 453 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Nigeria Dividend Information H1 2021ds
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 298 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        Notice of Board meeting and Closed Period June 2021
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 252 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Nigeria assigned highest possible Credit Ratings by
                        GCR
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 306 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        Media release clarifying concerns of MTN network
                        distribution in Nigeria
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 298 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Nigeria AGM Resolutions June 2021
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 1 MB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">MTN Nigeria Annual Report 2020</h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 1.11 MB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">MTN Nigeria Proxy Form May 2021</h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 94 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        Notice of Annual General Meeting
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 4 MB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Nigeria Board Meeting Resolutions - April 2021
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 342 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Nigeria Amended Dividend Information FY 2020
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 326 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Nigeria Statement - Dispute over the reduction in
                        Airtime Sales Commission
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 408 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Nigeria Commercial Paper &amp; Bond Programmes
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 421 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        Notice of Board Meeting and Closed Period March 2021
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 271 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Nigeria acquires additional 800MHz Spectrum
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 328 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Nigeria Dividend information FY 2020
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 320 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        Notification of Director Dealing - Karl Toriola
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 235 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        Notice of Board Meeting and Closed Period December 2020
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 264 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        NCC directs Network Operators to update SIM Registration
                        Records with National Identification Number
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 451 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        NCC directs Network Operators to suspend Sale of New SIM
                        Cards
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 424 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Group named most valuable African brand, worth
                        US$3,3 billion
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 269 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN steps up COVID fight with new campaign promoting
                        mask-wearing
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 598 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Nigeria appoints Karl Toriola as CEO Designate
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 563 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        Notice of Board Meeting and Closed Period (September
                        2020)
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 324 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        Shareholder Complaints Management Policy
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 1.3 MB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        Resignation of Rob Shuter from the Board of MTN Nigeria
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 289 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        Notice of Board Meeting and Closed Period (February
                        2020)
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 25.3 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        Developments Relating to the AGF Matter
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PD - -39 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Nigeria conducts a Trial of 5G Technology
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PD - -59 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Nigeria appoints a new Chief Financial Officer
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PD - -66 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        Media Clarification on USSD Billing
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 62.2 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        Notice of Board Meeting and Closed Period
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PD - -184 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">Media Report Clarification</h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 26 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        Announcement Regarding Status of Taxes Relating to the
                        2015 Fine
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PD - -189 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        Announcement Regarding Status of Taxes Relating to the
                        2015 Fine
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PD - -189 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Nigeria obtains Super-Agent Licence
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PD - -185 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Nigeria Corporate Actions Announcement
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PD - -174 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">Notification of Board Changes</h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PD - -2 MB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Nigeria rolls out 4G+New Service
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - 38.5 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Nigeria makes Final Payment of SIM Registration Fine
                        to NCC
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PD - - 133 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        Economic and Financial Crimes Commission Investigation
                        and Information Request
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PD - - 254 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Nigeria Signs N200bn Medium Term Loan Facility
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PD - -108 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN provides clarity on its Listing Price and Preference
                        Shares Redemption
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PD - -541 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        Announcement of board meeting date and closed period
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - -27 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Nigeria Interim Dividend Information June 2020
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - -368 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Nigeria and IHS expand scope of their Service
                        Agreement
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - -480 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Nigeria launches e-SIM Technology
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - -589 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Nigeria Notice of Board Meeting and Closed Period
                        June 2020
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - -321 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Nigeria N100 Billion Commercial Paper Issuance
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - -479 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Nigeria Business Continuity Disclosure on COVID-19
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - -586 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Nigeria N50 billion Commercial Paper Issuance
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - -339 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Nigeria AGM Resolutions May 2020
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PD - -435 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Nigeria Proxy Form April 2020
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PD - -47.4 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Nigeria Notice of Annual General Meeting
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PD - -1.22 MB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN-Nigeria-unveils-Yello-Hope-Package-to-address-impact-of-Covid-19
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - -1.09 MB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN-Nigeria-unveils-Yello-Hope-Package-to-address-impact-of-Covid-19
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - -1.09 MB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Nigeria Final Dividend Information 2019
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - -366 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        Board Meeting date and Closed period April 2020
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - -1.22 MB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">
                        MTN Nigeria Corporate Actions Announcement
                      </h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - -174 KB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox">
                <div className="mtn-card__content">
                  <div className="mtn-card--text-link__info">
                    <div className="mtn-card--text-link__title">
                      <h3 className="mtn-p">Notification of Board Changes</h3>
                    </div>
                    <p className="mtn-p mtn-text-smaller">PDF - -2 MB</p>
                  </div>
                  <a href="" className="mtn-fab-btn mtn-btn--skin-yellow">
                    <i className="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Announcement;
