import React from "react";
import { Col, Container, Form, Row, Accordion } from "react-bootstrap";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Black_layer from "../../assets/image/black_layer.png";
import Black_layer_m from "../../assets/image/black_layer_m.png";

const Footer = () => {
  return (
    <>
      <Router>
        <img src={Black_layer} alt="Black_layer" className="black-layer" />
        <img src={Black_layer_m} alt="Black_layer_m" className="black-layer_m" />
        <footer className="ft-start">
          <Container>
            <div className="mtn-footer-link desktop_footer">
              <Row className="mtn-ft-tog">
                <Col md={2} className="footer-link-block">
                  <Link to="https://www.mtn.ng/personal/" className="link_title">
                    Personal <span></span>{" "}
                  </Link>
                  <ul>
                    <li>
                      {" "}
                      <Link to="https://www.mtn.ng/personal/services/">Services</Link>{" "}
                    </li>
                    <li>
                      {" "}
                      <Link to="https://www.mtn.ng/personal/data/">Data</Link>{" "}
                    </li>
                    <li>
                      {" "}
                      <Link to="https://www.mtn.ng/personal/devices/">Devices</Link>{" "}
                    </li>
                    <li>
                      {" "}
                      <Link to="https://www.mtn.ng/personal/getting-started/">Getting Started</Link>{" "}
                    </li>
                    <li>
                      {" "}
                      <Link to="https://www.mtn.ng/personal/mymtnng-app/">myMTN NG App</Link>{" "}
                    </li>
                  </ul>
                </Col>
                <Col md={2} className="footer-link-block">
                  <Link to="https://www.mtn.ng/business/" className="link_title">
                    Business<span> </span>{" "}
                  </Link>
                </Col>
                <Col md={2} className="footer-link-block">
                  <Link to="https://www.mtn.ng/about-us/investor/" className="link_title">
                    Investors <span> </span>{" "}
                  </Link>
                  <ul>
                    <li>
                      {" "}
                      <Link to="https://www.mtn.ng/about-us/investor/more-in-investors/">More in Investors</Link>{" "}
                    </li>
                    <li>
                      {" "}
                      <Link to="https://www.mtn.ng/about-us/investor/shareholders/">Shareholders</Link>{" "}
                    </li>
                    <li>
                      {" "}
                      <Link to="https://www.mtn.ng/about-us/investor/financial-reporting/">Financial Reporting</Link>{" "}
                    </li>
                    <li>
                      {" "}
                      <Link to="https://www.mtn.ng/about-us/investor/announcements/">Announcementss</Link>{" "}
                    </li>
                  </ul>
                </Col>
                <Col md={2} className="footer-link-block">
                  <Link to="https://www.mtn.ng/foundation/" className="link_title">
                    Foundation <span> </span>{" "}
                  </Link>
                </Col>
                <Col md={2} className="footer-link-block">
                  <Link to="https://events.mtn.ng/" className="link_title">
                    Events <span> </span>{" "}
                  </Link>
                </Col>
                <Col md={2} className="footer-link-block">
                  <Link to="/#" className="link_title">
                    Support <span> </span>{" "}
                  </Link>
                  <ul>
                    <li>
                      {" "}
                      <Link to="https://mtnnigeria.egain.cloud/kb/get-help/home">Help Center</Link>{" "}
                    </li>
                    <li>
                      {" "}
                      <Link to="https://community.mtnnigeria.net/">Community</Link>{" "}
                    </li>
                    <li>
                      {" "}
                      <Link to="https://www.mtn.ng/just-for-you/">Just For You</Link>{" "}
                    </li>
                  </ul>
                </Col>
                <Col md={2} className="footer-link-block mt-5">
                  <Link to="https://www.mtn.ng/about-us/" className="link_title">
                    About Us <span> </span>{" "}
                  </Link>
                  <ul>
                    <li>
                      {" "}
                      <Link to="https://www.mtn.ng/about-us/who-we-are/our-code-of-ethics/">Our Code of Ethics</Link>{" "}
                    </li>
                    <li>
                      {" "}
                      <Link to="https://www.mtn.ng/about-us/who-we-are/about-us/privacy-and-data-protection-policy/">Privacy & Data Protection</Link>{" "}
                    </li>
                    <li>
                      {" "}
                      <Link to="https://www.mtn.ng/about-us/who-we-are/about-us/our-story/">Our Story</Link>{" "}
                    </li>
                    <li>
                      {" "}
                      <Link to="https://www.mtn.ng/about-us/who-we-are/press-and-insights/">Newsroom</Link>{" "}
                    </li>
                  </ul>
                </Col>
                <Col md={2} className="footer-link-block mt-5">
                  <Link to="/#" className="link_title">
                    Careers <span> </span>{" "}
                  </Link>
                  <ul>
                    <li>
                      {" "}
                      <Link to="https://ehle.fa.em2.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_1/requisitions">Apply for Jobs</Link>{" "}
                    </li>
                    <li>
                      {" "}
                      <Link to="https://www.mtn.ng/mtn-careers-faq/">Frequently Asked Questions</Link>{" "}
                    </li>
                    <li>
                      {" "}
                      <Link to="https://www.mtn.ng/diversity-and-inclusion-policy/">Diversity & Inclusion Policy</Link>{" "}
                    </li>
                  </ul>
                </Col>
                <Col md={2} className="footer-link-block mt-5">
                  <Link to="https://www.mtn.ng/contact-us/" className="link_title">
                    Contact Us <span> </span>{" "}
                  </Link>
                </Col>
              </Row>
            </div>
          </Container>
          <div className="mob_footer">
            <Accordion id="menu_mob_s">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <div className="card-header">
                    <Link to="https://www.mtn.ng/personal/">Personal</Link>
                    <i class="fa-solid fa-minus minus"></i>
                    <i class="fa-solid fa-plus plus"></i>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <Link to="https://www.mtn.ng/personal/services/" className="toglink">
                    Services
                  </Link>
                  <Link to="https://www.mtn.ng/personal/data/" className="toglink">
                    Data
                  </Link>
                  <Link to="https://www.mtn.ng/personal/devices/" className="toglink">
                    Devices
                  </Link>
                  <Link to="https://www.mtn.ng/personal/getting-started/" className="toglink">
                    Getting Started
                  </Link>
                  <Link to="https://www.mtn.ng/personal/mymtnng-app/" className="toglink">
                    myMTN NG App
                  </Link>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <div className="card-header">
                    <Link to="https://www.mtn.ng/business/">Business</Link>
                  </div>
                </Accordion.Header>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  <div className="card-header">
                    <Link to="https://www.mtn.ng/about-us/investor/">Investors</Link>
                    <i class="fa-solid fa-minus minus"></i>
                    <i class="fa-solid fa-plus plus"></i>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <Link to="https://www.mtn.ng/about-us/investor/" className="toglink">
                    More in Investors
                  </Link>
                  <Link to="https://www.mtn.ng/about-us/investor/shareholders/" className="toglink">
                    Shareholders
                  </Link>
                  <Link to="https://www.mtn.ng/about-us/investor/financial-reporting/" className="toglink">
                    Financial Reporting
                  </Link>
                  <Link to="https://www.mtn.ng/about-us/investor/announcements/" className="toglink">
                    Announcments
                  </Link>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  <div className="card-header">
                    <Link to="https://www.mtn.ng/foundation/">Foundation</Link>
                  </div>
                </Accordion.Header>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  <div className="card-header">
                    <Link to="https://events.mtn.ng/">Events</Link>
                  </div>
                </Accordion.Header>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  <div className="card-header">
                    <Link to="/#">Support</Link>
                    <i class="fa-solid fa-minus minus"></i>
                    <i class="fa-solid fa-plus plus"></i>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <Link to="https://mtnnigeria.egain.cloud/kb/get-help/home" className="toglink">
                    Help Center
                  </Link>
                  <Link to="https://community.mtnnigeria.net/" className="toglink">
                    Community
                  </Link>
                  <Link to="https://www.mtn.ng/just-for-you/" className="toglink">
                    Just For You
                  </Link>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>
                  <div className="card-header">
                    <Link to="https://www.mtn.ng/about-us/">About Us</Link>
                    <i class="fa-solid fa-minus minus"></i>
                    <i class="fa-solid fa-plus plus"></i>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <Link to="https://www.mtn.ng/about-us/who-we-are/our-code-of-ethics/" className="toglink">
                    Our Code of Ethics
                  </Link>
                  <Link to="https://www.mtn.ng/about-us/who-we-are/about-us/privacy-and-data-protection-policy/" className="toglink">
                    Privacy & Data Protection
                  </Link>
                  <Link to="https://www.mtn.ng/about-us/who-we-are/about-us/our-story/" className="toglink">
                    Our Story
                  </Link>
                  <Link to="https://www.mtn.ng/about-us/who-we-are/press-and-insights/" className="toglink">
                    Newsroom
                  </Link>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7">
                <Accordion.Header>
                  <div className="card-header">
                    <Link to="/#">Careers</Link>
                    <i class="fa-solid fa-minus minus"></i>
                    <i class="fa-solid fa-plus plus"></i>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <Link to="https://www.mtn.ng/mtn-careers-faq/" className="toglink">
                    Apply for Jobs
                  </Link>
                  <Link to="https://www.mtn.ng/mtn-careers-faq/" className="toglink">
                    Frequently Asked Questions
                  </Link>
                  <Link to="https://www.mtn.ng/diversity-and-inclusion-policy/" className="toglink">
                    Diversity & Inclusion Policy
                  </Link>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="8">
                <Accordion.Header>
                  <div className="card-header">
                    <Link to="https://www.mtn.ng/contact-us/">Contact Us</Link>
                  </div>
                </Accordion.Header>
              </Accordion.Item>
            </Accordion>
          </div>
          <Container>
            <div className="mtn-nigeria-social">
              <Row>
                <Col md={5} className="mtn-copy-link">
                  <div className="mtn-ft-logo">
                    <div className="mtn-social-link order-suc-social">
                      <ul>
                        <li>
                          <Link to="/#">
                            <i class="fa fa-facebook" aria-hidden="true"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/#">
                            <i class="fa fa-twitter" aria-hidden="true"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/#">
                            <i class="fa fa-instagram" aria-hidden="true"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/#">
                            <i class="fa fa-linkedin" aria-hidden="true"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/#">
                            <i
                              class="fa fa-youtube-play"
                              aria-hidden="true"
                            ></i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="ft-logo-text">
                      © 2022 MTN Nigeria Communications PLC, All rights
                      reserved. Privacy Policy & Data Protection
                    </div>
                  </div>
                </Col>
                <Col md={5} className="mtn-nigeria">
                  <div className="mtn-select-lang-block">
                    <div className="country">
                      <div id="country" className="select">
                        <span className="flag-img">
                          <img
                            alt="Flag"
                            src="https://selfservice.thestorywallcafe.com/html/selfservice/images/nigeria-flag.png"
                            border="0"
                          />
                        </span>
                        Nigeria
                      </div>
                      <div id="country-drop" className="dropdown">
                        <ul>
                          <li>Afghanistan</li>
                          <li>Benin</li>
                          <li>Botswana</li>
                          <li>
                            <span className="flag-img">
                              <img
                                alt="Nigeria"
                                src="https://selfservice.thestorywallcafe.com/html/selfservice/images/nigeria-flag.png"
                                border="0"
                              />
                            </span>
                            Nigeria
                          </li>
                          <li>Guinea Bissau</li>
                          <li> Cameroon </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </footer>
      </Router>
    </>
  );
};

export default Footer;
