import React from 'react'
const Adminsidebar = () => {	
	return (
		<div className='sidebar'>
			<ul className='sidebar_ul'>
				<li>
					<a href="/admin/" className='sidebmenu'><i class="fa fa-tachometer" aria-hidden="true"></i> Dashboard</a>
				</li>
				<li>
					<a href="/admin/home" className='sidebmenu'><i class="fa fa-home" aria-hidden="true"></i> Home</a>
				</li> 
				<li>
					<a href="/admin/investors/" className='sidebmenu'><i class="fa fa-money" aria-hidden="true"></i> Investors</a>
					<div className='hover_menu'>
						<a href="/admin/more-in-investors/" className='submenu'>More in Investors</a>
						<a href="/admin/shareholders/" className='submenu'>Shareholders</a>
						<a href="/admin/financial" className='submenu'>Finance Reporting</a>
						<a href="/admin/announcement/" className='submenu'>Announcements</a>
					</div>
				</li>
				<li>
					<a href="/admin/settings/" className='sidebmenu'><i class="fa fa-cogs" aria-hidden="true"></i> Settings </a>
					
				</li>
			</ul>
		</div>
	)
}
export default Adminsidebar

