
import React, { useRef, useState } from 'react'
import Slider from 'react-slick'
import Routerimg from "../../assets/image/announcement.jpg"
import Fivegbanner from "../../assets/image/board-of-directors.jpg"
import Amazonweb from "../../assets/image/dividendone.jpg"

export default function InvestorhomeCarousel() {
	const sliderRef = useRef()
	const [sliderIndex, serSliderIndex] = useState(0)
	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: false,
	}

	return (
		<div>
			<Slider
				afterChange={(e) => {
					serSliderIndex(e)
				}}
				ref={sliderRef}
				arrows={null}
				{...settings}
			>
				<div className="item bg_before">
					<div className="productimg relative">
						<img className="relative productimg" src={Routerimg} alt="Routerimg"/>
						<div className="pos_abs">
							<div className="container">
								<h3 className="mtn-banner__header mtn-h1">MTN Nigeria 2023 Annual General Meeting</h3>
								<button className="btn buy-now">Click here <i class="fa fa-chevron-right ml-20" aria-hidden="true"></i></button>
							</div>
						</div>
					</div>
				</div>
				<div className="item bg_before">
					<div className="productimg relative">
						<img className="relative productimg" src={Fivegbanner} alt="Fivegbanner"/>
						<div className="pos_abs">
							<div className="container">
								<h3 className="mtn-banner__header mtn-h1">Get further information on the Incentive Shares</h3>
								<button className="btn buy-now">Click Here <i class="fa fa-chevron-right ml-20" aria-hidden="true"></i></button>
							</div>
						</div>
					</div>
				</div>
				<div className="item bg_before">
					<div className="productimg relative">
						<img className="relative productimg" src={Amazonweb} alt="Amazonweb"/>
						<div className="pos_abs">
							<div className="container">
								<h3 className="mtn-banner__header mtn-h1">Get Information on Unclaimed Dividends</h3>
								<button className="btn buy-now">Click Here <i class="fa fa-chevron-right ml-20" aria-hidden="true"></i></button>
							</div>
						</div>
					</div>
				</div>
			</Slider>
		</div>
	)
}
