import React from "react";
import JsonData from './annual.json';


const Annual = () => {    
    const DisplayData=JsonData.map(
        (info)=>{
            return(
                <tr>
                    <td>{info.title}</td>
                    <td>{info.filetype}</td>
                    <td>{info.filesize}</td>
                    <td>
                        <a href="javascript:void(0);" className='anc_edt'>Edit</a> 
                        <a href="javascript:void(0);" className='anc_del'>Delete</a>
                    </td>
                </tr>
            )
        }
    )
    return(
        <>
            
                        <div className="dashboard-full-wrap send-question-lect-content custom_tabs mt-2">
                            <table className='striped custom_table w-100'>
                                    <thead>
                                        <tr>
                                            <th>Title</th>
                                            <th>File Type</th>
                                            <th>File Size</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>                
                                        {DisplayData}
                                    </tbody>
                            </table> 
                        </div>
        </>
    )
}
export default Annual