import React, { useState } from "react";
import AdminHeader from "../../admincommen/header";
import Adminsidebar from "../../admincommen/sidebar";
import AdminFooter from "../../admincommen/footer";
import MoreininvestorBanner from "./moreinvestor-banner";
import MoreininverContent from "./moreinver-content";
import MoreininverContentform from "./moreininvestors-content-form";
import Morebannerform from "./morebanner-form";

const Moreininvestor = () => {
    const [step, setClassStep] = useState(false); 
    const removeActive = () => {
        setClassStep(!step); 
    }
    const [step1, setClassStep1] = useState(true); 
    const addactbtn = () => {
        setClassStep1(!step1); 
    }

    const [annual, setAnnual] = useState(false); 
    const annualremove = () => {
        setAnnual(!annual); 
    }
    const [annual1, setAnnual1] = useState(true); 
    const annualbtn = () => {
        setAnnual1(!annual1); 
    }
    
    return(
        <>
            <AdminHeader/>
            <Adminsidebar/>
            <div className='wrap'>
				<div className='content_wrap' id='dashboard'>
                    <h5 class="bundle-type-heading"><b>Investors</b></h5>
                    <div className="card_box">
                        <div className="d-flex align-items-center justify-between">
                            <h4 className="f15 mb-0">more in investors</h4>
                            <a href="javascript:void(0);" className="mb-0 buy-now" id="bnone" onClick={()=>{addactbtn(); removeActive();}}>Add New Banner</a>
                        </div>
                        <div className="dashboard-full-wrap send-question-lect-content custom_tabs mt-2">
                            <div className={`banner_sec ${step1 ? "active" : "" }`}>
                                <MoreininvestorBanner /> 
                            </div>
                        </div>
                        <div className={`banner_for_sec ${step ? "active" : "" }`}>
                            <Morebannerform />
                        </div>
                    </div>
                    <div className="card_box mt-4">
                        <div className="d-flex align-items-center justify-between">
                            <h4 className="f15 mb-0">More in investors Report</h4>    
                            <a href="javascript:void(0);" className="mb-0 buy-now" id="bnone" onClick={()=>{annualbtn(); annualremove();}}>Add New</a>
                        </div>
                        <div className="dashboard-full-wrap send-question-lect-content custom_tabs mt-2">
                            <div className={`banner_sec ${annual1 ? "active" : "" }`}>
                                 <MoreininverContent/>
                            </div>
                            <div className={`banner_for_sec ${annual ? "active" : "" }`}>
                                <MoreininverContentform />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AdminFooter/>
        </>
    )
}
export default Moreininvestor