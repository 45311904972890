import React from 'react'
import InvestorhomeCarousel from "./InvestorhomeCarousel";
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Invmore from "../../assets/image/inv-moreInvest.jpg";
import Gray_up from "../../assets/image/gray_up.png";
import Gray_up_m from "../../assets/image/gray_up_m.png";

const Moreinvestor = () => {
  return (
    <>
      <section className="banner-home">
        <div className="outer thu-ban">
          <div id="big" className="owl-carousel owl-theme">
            <div className='page_banner relative bg_before'>
                <img src={Invmore} alt='Invmore' className='ban_img productimg' />
                <div class="pos_abs">
                    <div className='item'>
                        <Container>
                            <div class="mtn-strapline">Investors</div>
                            <h1 class="mtn-banner__header mtn-h1">More In Investors</h1>
                        </Container>
                    </div>
                </div>
            </div>
          </div>
        </div>
	</section>
      <img src={Gray_up} alt="Gray_up" className="gray_up mtn46" />
      <img src={Gray_up_m} alt="Gray_up_m" className="gray_up_m" />
      <section className='mtn-bg--white mnegt-27'>
        <Container>
            <Row>
                <Col md={6}>
                    <div className='mtn-card mtn-card-one mtn-card--shadow mtn-card--image mtn-card--link  mtn-card--link--fab mtn-card--cursor' id="h_auto">
                        <div className='mtn-card__content'>
                            <div className='mtn-tag mtn-tag--yellow'>More in investors </div>
                            <div className='flex'>
                                <h3 className='mtn-card__header mtn-h2'>MTN Nigeria Listing</h3>
                                <button type="default" className="mtn-fab-btn--mini  mtn-btn--skin-yellow" onclick="window.open('https://www.mtnonline.com/about-us/investor/more-in-investors/events-calendar/','_self')">
                                <i class="fa-sharp fa-solid fa-chevron-right"></i> 
                                </button>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col md={6}>
                    <div className='mtn-card mtn-card--shadow mtn-card--image mtn-card--link  mtn-card--link--fab mtn-card--cursor bg_item' id="h_auto">
                        <div className='mtn-card__content'>
                            <div className='mtn-tag mtn-tag--yellow'>More in investors </div>
                            <div className='flex'>
                                <h3 className='mtn-card__header mtn-h2'>Presentations</h3>
                                <button type="default" class="mtn-fab-btn--mini  mtn-btn--skin-yellow" onclick="window.open('https://www.mtnonline.com/about-us/investor/more-in-investors/events-calendar/','_self')">
                                <i class="fa-sharp fa-solid fa-chevron-right"></i> 
                                </button>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col md={6}>
                    <div className='mtn-card mtn-card--shadow mtn-card--image mtn-card--link  mtn-card--link--fab mtn-card--cursor bg_ima' id="h_auto">
                        <div className='mtn-card__content'>
                            <div className='mtn-tag mtn-tag--yellow'>More in investors </div>
                            <div className='flex'>
                                <h3 className='mtn-card__header mtn-h2'>Events Calendar</h3>
                                <button type="default" class="mtn-fab-btn--mini  mtn-btn--skin-yellow" onclick="window.open('https://www.mtnonline.com/about-us/investor/more-in-investors/events-calendar/','_self')">
                                <i class="fa-sharp fa-solid fa-chevron-right"></i> 
                                </button>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
      </section>
    </>
  )
}

export default Moreinvestor