import React from "react";
import JsonData from './Financialrepo.json';


const Financialrepo = () => {    
    const DisplayData=JsonData.map(
        (info)=>{
            return(
                <tr>
                    <td>{info.id}</td>
                    <td>{info.title}</td>
                    <td>{info.Description}</td>
                    <td>
                        <a href="javascript:void(0);" className='anc_edt'>Edit</a> 
                        <a href="javascript:void(0);" className='anc_del'>Delete</a>
                    </td>
                </tr>
            )
        }
    )
    return(
        <>
            
            <div className="dashboard-full-wrap send-question-lect-content custom_tabs mt-2">
                <table className='striped custom_table w-100'>
                    <thead>
                        <tr>
                            <th>Sn</th>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                        <tbody>                
                            {DisplayData}
                        </tbody>
                 </table> 
            </div>
        </>
    )
}
export default Financialrepo