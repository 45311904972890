import React, { Fragment, useRef, useState } from 'react'
import Slider from 'react-slick'
import Shopone from "../../assets/image/shopone.png"
import Shoptwo from "../../assets/image/shoptwo.png"
import { Col, Container } from 'react-bootstrap'

export default function ShopCarousel() {
	const sliderRef = useRef()
	const [sliderIndex, serSliderIndex] = useState(0)
	const settings = {
		centerMode:true,
	    slidesToShow: 3,
	    slidesToScroll: 3,																																																																																																																																																																																																																																																																																																																							
	    dots: true,
	    infinite: true,
	    cssEase: 'linear',
	    centerPadding:"120px",
	    arrows: true,

		responsive: [
			{
				breakpoint: 764,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: true,
	    			centerPadding:"30px",
	    			arrows: false,
				},
			},
		],
	}
	return (
		<Fragment>
			<Slider
				afterChange={(e) => {
					serSliderIndex(e)
				}}
				ref={sliderRef}
				arrows={null}
				{...settings}
			>
				<div className="item">
				<a href='https://shop.mtn.ng/' className='red_link white-text z9'>
					<div className="mtn-card mtn-card--shadow mtn-card--image mtn-card--link  mtn-card--cursor" id='h390'>
						
							<img className="shop" src={Shopone} alt="Shopone"/>
							<div className="mtn-card__content">
								<h3 class="mtn-card__header mtn-h2 d-flex  justify-between w-100 black-text">Peak Lite (7AM- 7PM)
								</h3>
								<p className='shop w-100'>Get 500MB for N100 and enjoy browsing from 7am to 7pm.</p>
								<div className='price-validity'>
									<div className='price-block'>
										<div className='pro-av'>Price</div>
										<div className='trend-price'>
											<span className='price'><b>₦150.00</b></span>
										</div>
									</div>
									<span className='line_yellow'></span>
									<div className='validity-block'>
										<div class="val-txt"> Validity </div>
										<div class="val-dt"><b>1 days</b> </div>
									</div>
								</div>
								{/* <button className="mtn-btn  mtn-btn--text-right-icon mtn-btn--skin-black">Learn More <i class="fa fa-chevron-right ml-20" aria-hidden="true"></i></button> */}
							</div>
					</div>
						</a>
				</div>{' '}
				<div className="item">
					<a href='https://shop.mtn.ng/' className='red_link white-text z9'>
					<div className="mtn-card mtn-card--shadow mtn-card--image mtn-card--link  mtn-card--cursor" id='h390'>
						
							<img className="shop" src={Shoptwo} alt="Shoptwo"/>
							<div className="mtn-card__content">
								<h3 class="mtn-card__header mtn-h2 d-flex  justify-between w-100 black-text">25GB SME Data Plan
								</h3>
								<p className='shop w-100'>Get 25GB at N10,000 to share with 50 beneficiaries. Valid for 30days</p>
								<div className='price-validity'>
									<div className='price-block'>
										<div className='pro-av'>Price</div>
										<div className='trend-price'>
											<span className='price'>₦10,000.00</span>
										</div>
									</div>
									<span className='line_yellow'></span>
									<div className='validity-block'>
										<div class="val-txt"> Validity </div>
										<div class="val-dt"> 30 days </div>
									</div>
								</div>
								{/* <button className="mtn-btn  mtn-btn--text-right-icon mtn-btn--skin-black">Learn More <i class="fa fa-chevron-right ml-20" aria-hidden="true"></i></button> */}
							</div>
					</div>
						</a>
				</div>{' '}
				<div className="item">
				<a href='https://shop.mtn.ng/' className='red_link white-text'>
					<div className="mtn-card mtn-card--shadow mtn-card--image mtn-card--link  mtn-card--cursor" id='h390'>
						
							<img className="shop" src={Shopone} alt="Shopone"/>
							<div className="mtn-card__content">
								<h3 class="mtn-card__header mtn-h2 d-flex  justify-between w-100 black-text">SMB Lite
								</h3>
								<p className='shop w-100'>Get 750MB to access Facebook, WhatsApp & Instagram for N150. Valid for 2 days</p>
								<div className='price-validity'>
									<div className='price-block'>
										<div className='pro-av'>Price</div>
										<div className='trend-price'>
											<span className='price'>₦150.00</span>
										</div>
									</div>
									<span className='line_yellow'></span>
									<div className='validity-block'>
										<div class="val-txt"> Validity </div>
										<div class="val-dt"> 2 days </div>
									</div>
								</div>
								{/* <button className="mtn-btn  mtn-btn--text-right-icon mtn-btn--skin-black">Learn More <i class="fa fa-chevron-right ml-20" aria-hidden="true"></i></button> */}
							</div>
					</div>
						</a>
				</div>{' '}
				<div className="item">
				<a href='https://shop.mtn.ng/' className='red_link white-text'>
					<div className="mtn-card mtn-card--shadow mtn-card--image mtn-card--link  mtn-card--cursor" id='h390'>
						
							<img className="shop" src={Shopone} alt="Shopone"/>
							<div className="mtn-card__content">
								<h3 class="mtn-card__header mtn-h2 d-flex  justify-between w-100 black-text">Peak Lite (7AM- 7PM)
								</h3>
								<p className='shop w-100'>Get 500MB for N100 and enjoy browsing from 7am to 7pm.</p>
								<div className='price-validity'>
									<div className='price-block'>
										<div className='pro-av'>Price</div>
										<div className='trend-price'>
											<span className='price'><b>₦150.00</b></span>
										</div>
									</div>
									<span className='line_yellow'></span>
									<div className='validity-block'>
										<div class="val-txt"> Validity </div>
										<div class="val-dt"><b>1 days</b> </div>
									</div>
								</div>
								{/* <button className="mtn-btn  mtn-btn--text-right-icon mtn-btn--skin-black">Learn More <i class="fa fa-chevron-right ml-20" aria-hidden="true"></i></button> */}
							</div>
					</div>
						</a>
				</div>{' '}
				<div className="item">
				<a href='https://shop.mtn.ng/' className='red_link white-text'>
					<div className="mtn-card mtn-card--shadow mtn-card--image mtn-card--link  mtn-card--cursor" id='h390'>
						
							<img className="shop" src={Shoptwo} alt="Shoptwo"/>
							<div className="mtn-card__content">
								<h3 class="mtn-card__header mtn-h2 d-flex  justify-between w-100 black-text">25GB SME Data Plan
								</h3>
								<p className='shop w-100'>Get 25GB at N10,000 to share with 50 beneficiaries. Valid for 30days</p>
								<div className='price-validity'>
									<div className='price-block'>
										<div className='pro-av'>Price</div>
										<div className='trend-price'>
											<span className='price'>₦10,000.00</span>
										</div>
									</div>
									<span className='line_yellow'></span>
									<div className='validity-block'>
										<div class="val-txt"> Validity </div>
										<div class="val-dt"> 30 days </div>
									</div>
								</div>
								{/* <button className="mtn-btn  mtn-btn--text-right-icon mtn-btn--skin-black">Learn More <i class="fa fa-chevron-right ml-20" aria-hidden="true"></i></button> */}
							</div>
					</div>
						</a>
				</div>{' '}
				<div className="item">
				<a href='https://shop.mtn.ng/' className='red_link white-text'>
					<div className="mtn-card mtn-card--shadow mtn-card--image mtn-card--link  mtn-card--cursor" id='h390'>
						
							<img className="shop" src={Shopone} alt="Shopone"/>
							<div className="mtn-card__content">
								<h3 class="mtn-card__header mtn-h2 d-flex  justify-between w-100 black-text">SMB Lite
								</h3>
								<p className='shop w-100'>Get 750MB to access Facebook, WhatsApp & Instagram for N150. Valid for 2 days</p>
								<div className='price-validity'>
									<div className='price-block'>
										<div className='pro-av'>Price</div>
										<div className='trend-price'>
											<span className='price'>₦150.00</span>
										</div>
									</div>
									<span className='line_yellow'></span>
									<div className='validity-block'>
										<div class="val-txt"> Validity </div>
										<div class="val-dt"> 2 days </div>
									</div>
								</div>
								{/* <button className="mtn-btn  mtn-btn--text-right-icon mtn-btn--skin-black">Learn More <i class="fa fa-chevron-right ml-20" aria-hidden="true"></i></button> */}
							</div>
					</div>
						</a>
				</div>{' '}
			</Slider>
		</Fragment>
	)
}
