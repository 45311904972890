import React, { Component } from 'react';
import Editor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react'

const EditorComponent = ( props ) => (
	<CKEditor
		editor={ Editor }
		onReady={ editor => {
			props.parent.setState( { showButton: true } );

			window.editor = editor;
			// You can store the "editor" and use when it is needed.
			console.log( 'Editor is ready to use!', editor );

		} }
		onChange={ ( event, editor ) => {
			const data = editor.getData();
			console.log( { event, editor, data } );
		} }
		onBlur={ ( event, editor ) => {
			console.log( 'Blur.', editor );
		} }
		onFocus={ ( event, editor ) => {
			console.log( 'Focus.', editor );
		} }
	/>
);

class Investoreditor extends Component {
	constructor( ...all ) {
		super( ...all );

		this.state = {
			showButton: false
		}
	}

	render() {
		return (
			<div className="App">
				<EditorComponent parent={ this } />
				{/* { this.state.showButton ? <Button>Button</Button> : '' } */}
			</div>
		);
	}
}

export default Investoreditor;