import React from "react";
import { Col,  Row} from "react-bootstrap";
import Eventone from "../../assets/image/midal-light.jpg";
import Eventtwo from "../../assets/image/light-aje.jpg";
import Eventthree from "../../assets/image/afi-event-port.jpg";

const Pastevent = () =>{
    return(
        <>
            <Row>
                <Col md={4} sm={6} xs={12}>
                    <div className="eventbox">
                        <div className="event_img position-relative">
                            <a href="#/">
                                <img src={Eventone} alt="Eventone" className="evnt_img" />
                            </a>
                            <div class="date date_abs">29th, Jan 2023</div>
                        </div>
                        <div className="event_content">
                            <a href="#/" className="event_link">Mipad100 Partners with MTN for Annual Launch</a>
                            <div className="py-3">
                                <i className="fa-solid fa-location-dot"></i>
                            </div>
                            <p className="ellithree">With the right foot forward, we are starting the year in the midst of great opportunities!</p>
                            <a href="#/" className="btn-buy readbtn">Read More</a>
                        </div>
                    </div>
                </Col>
                <Col md={4} sm={6} xs={12}>
                    <div className="eventbox">
                        <div className="event_img position-relative">
                            <a href="#/">
                                <img src={Eventtwo} alt="Eventtwo" className="evnt_img" />
                            </a>
                            <div class="date date_abs">29th, Jan 2023</div>
                        </div>
                        <div className="event_content">
                            <a href="#/" className="event_link">MTN mPulse Spelling Bee Grand Finale: Ene Kate David Emerges as Champions</a>
                            <div className="py-3">
                                <i className="fa-solid fa-location-dot"></i>
                            </div>
                            <p className="ellithree">Previous editions of the grand finale of MTN mPulse Spelling bees have always been packed with loads of suspense & excitement, and this year was no exception!</p>
                            <a href="#/" className="btn-buy readbtn">Read More</a>
                        </div>
                    </div>
                </Col>
                <Col md={4} sm={6} xs={12}>
                    <div className="eventbox">
                        <div className="event_img position-relative">
                            <a href="#/">
                                <img src={Eventthree} alt="Eventthree" className="evnt_img" />
                            </a>
                            <div class="date date_abs">29th, Jan 2023</div>
                        </div>
                        <div className="event_content">
                            <a href="#/" className="event_link">AFRIFF’s Seven days of Entertainment, Movies and Educational Content</a>
                            <div className="py-3">
                                <i className="fa-solid fa-location-dot"></i>
                            </div>
                            <p className="ellithree">Glamour, smiles, fashion, and more were on show on the red carpet on the night of the AFRIFF Globe Awards – a special recognition awards night to appreciate and encourage Filmmakers who have exceptionally stood out in their creative fields as the 2022 African Film Festival wrapped up on Sunday.</p>
                            <a href="#/" className="btn-buy readbtn">Read More</a>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    )
}
export default Pastevent