import React from 'react'
import DataTable from 'react-data-table-component';
import data from './userdata.json'

const columns = [
    {
      name: 'User ID',
      selector: 'uid',
      sortable: true,
    },
    {
      name: 'User Name',
      selector: 'uname',
      sortable: true,
    },
    {
      name: 'Email ID',
      selector: 'email',
      sortable: true,
    },
    {
      name: 'Create At',
      selector: 'createat',
      sortable: true,
    }
  ];
export default function Users() {
    return(
        <>
            <div className='wrap'>
                <div className=''>
                    <div className='body_content'>
                        <DataTable className='striped custom_table'
                            columns={columns}
                            data={data}
                            pagination
                            highlightOnHover
                        />
                    </div>
                </div>
            </div>
        </>
    )
}