import React, { Fragment, useRef, useState } from 'react'
import Slider from 'react-slick'
import Ulu from "../../assets/image/ulu.jpg";
import Godson from "../../assets/image/godson.jpg";
import Omoyeni from "../../assets/image/omoyeni.jpg";
import Okoro from "../../assets/image/okoro.jpg";
import Denial from "../../assets/image/denial.jpg";
import Odunlade from "../../assets/image/odunlade.jpg";
import { Container } from 'react-bootstrap'

export default function ProjectCarousel() {
	const sliderRef = useRef()
	const [sliderIndex, serSliderIndex] = useState(0)
	const settings = {
        centerMode: true,
	    slidesToShow: 1,
	    slidesToScroll: 1,
	    dots: true,
	    infinite: true,
	    cssEase: 'linear',
	    arrows: false,
        centerPadding:"33.333%",
		autoplay: true,
		autoplaySpeed: 2500,
		speed: 500,
		responsive: [
			{
				breakpoint: 764,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: true,
	    			centerPadding:"20px",
	    			arrows: false,
				},
			},
		],
	}
	return (
		<Fragment>
            <Container>
			<Slider
				afterChange={(e) => {
					serSliderIndex(e)
				}}
				ref={sliderRef}
				arrows={null}
				{...settings}
			>
				<div className="item">
					<div className="card text-center">
                        <img className="card-img-top" src={Ulu} alt="Ulu"/>
                        <div className='card-body'>
                            <h5>Ugbaga Ulu, <span className='f14'>ICT and Business Skills Training recipient </span>
                                <br/>                        
                            </h5>
                            <p class="card-text text-left">MTN's ICT and business skills training was life-changing. It helped
                        me discover my passion for Computer-aided design and improved my skills in AutoCad. Thanks to
                        the training, I am now a CAD engineer. I am grateful for the opportunity MTN provided.</p>
                        </div>
					</div>
				</div>{' '}
                <div className="item">
					<div className="card text-center">
                        <img className="card-img-top" src={Godson} alt="Godson"/>
                        <div className='card-body'>
                            <h5>Izuogu Godson, <span className='f14'>ICT and Business Skills Training recipient</span>
                                <br/>                        
                            </h5>
                            <p class="card-text text-left">I had the opportunity to attend the ICT and Business Skills training
                                and from there, gained the required momentum to kickstart my career in the ICT field. Today, I'm
                                thrilled to share that I am now a data and machine learning scientist and budding back-end web
                                developer. This knowledge has helped me start paying my bills.</p>
                        </div>
					</div>
				</div>{' '}
                <div className="item">
					<div className="card text-center">
                        <img className="card-img-top" src={Omoyeni} alt="Omoyeni"/>
                        <div className='card-body'>
                            <h5>Oluwakemi Omoyeni, <span className='f14'>STSS recipient</span>
                                <br/>                        
                            </h5>
                            <p class="card-text text-left">Raised by a single mother, it wasn't easy for her to pay my school
                                fees but as a result of this scholarship, I was not only able to pay my school fees with ease
                                but also get a laptop to learn software development.
                                After I wrote my final exam in school, I got a job with a tech company immediately. Thank you
                                MTN for this privilege once again.
                            </p>
                        </div>
					</div>
				</div>{' '}
                <div className="item">
					<div className="card text-center">
                        <img className="card-img-top" src={Okoro} alt="Okoro"/>
                        <div className='card-body'>
                            <h5>Ifeoma Okoro, <span className='f14'>SSBS recipient</span>
                                <br/>                        
                            </h5>
                            <p class="card-text text-left">The MTN scholarship helped me improve my communication skills and compete better with my peers.
                                They gave me a laptop with Jaws access and speech capabilities which enables me to read my
                                lecturers' electronic materials. I have now published an anthology of 22 poems titled 'Entrails
                                of my being' thanks to this opportunity.
                            </p>
                        </div>
					</div>
				</div>{' '}
                <div className="item">
					<div className="card text-center">
                        <img className="card-img-top" src={Odunlade} alt="Odunlade"/>
                        <div className='card-body'>
                            <h5>Olaniyi Odunlade, <span className='f14'>STSS recipient</span>
                                <br/>                        
                            </h5>
                            <p class="card-text text-left">MTN Foundation scholarship helped me financially throughout my
                                university education and career progression. It also provided valuable training and insights for
                                my professional qualifications. The scholarship enabled me to achieve my career goal of
                                utilizing FinTech and ICT to improve financial management and accounting.
                            </p>
                        </div>
					</div>
				</div>{' '}
                <div className="item">
					<div className="card text-center">
                        <img className="card-img-top" src={Denial} alt="Denial"/>
                        <div className='card-body'>
                            <h5>Daniel Enwerem, <span className='f14'>SSBS recipient</span>
                                <br/>                        
                            </h5>
                            <p class="card-text text-left">MTN's Y'ello Experience provided unique opportunities. MTN
                                Foundation's training gave me technical skills required for my undergraduate studies and enabled
                                me to enrol in a postgrad programme. MTN has had a remarkable impact on my life, and I am
                                grateful for their support.
                            </p>
                        </div>
					</div>
				</div>{' '}
			</Slider>
            </Container>
		</Fragment>
	)
}
