import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Invmore from "../../assets/image/WWD-Digital-Services.jpg";
import Gray_up from "../../assets/image/gray_up.png";
import Gray_up_m from "../../assets/image/gray_up_m.png";

const Shareholders = () => {
  return (
    <>
      <section className="banner-home">
        <div className="outer thu-ban">
          <div id="big" className="owl-carousel owl-theme">
            <div className='page_banner relative bg_before'>
                <img src={Invmore} alt='Invmore' className='ban_img productimg' />
                <div class="pos_abs">
                    <div className='item'>
                        <Container>
                            <div class="mtn-strapline">Investors</div>
                            <h1 class="mtn-banner__header mtn-h1">Financial Reporting</h1>
                        </Container>
                    </div>
                </div>
            </div>
          </div>
        </div>
	</section>
      <img src={Gray_up} alt="Gray_up" className="gray_up mtn46" />
      <img src={Gray_up_m} alt="Gray_up_m" className="gray_up_m" />
      <section className='mtn-bg--white mnegt-27'>
        <Container>
            <Row>
                <Col md={12}>
                    <div className="">
                        <h3 class="mtn-h4 mtn-section-header__heading">Quarterly Results</h3>
                        <p>Download our quarterly results of the current year and past years…</p>
                        <button id="" onclick="window.open('https://www.mtnonline.com/about-us/investor/financial-reporting/quarterly-results/','_self')" type="default" class="mtn-btn  mtn-btn--text-right-icon mtn-btn--skin-yellow">
                            <span class="btn-text mr-2 ">View More</span>
                            <i class="fa-solid fa-chevron-right"></i>
                         </button>
                    </div>
                </Col>
                <Col md={12}>
                    <div className="">
                        <h3 class="mtn-h4 mtn-section-header__heading">Annual Results</h3>
                        <p>Download our annual results of the current year and past years.</p>
                        <button id="" onclick="window.open('https://www.mtnonline.com/about-us/investor/financial-reporting/quarterly-results/','_self')" type="default" class="mtn-btn  mtn-btn--text-right-icon mtn-btn--skin-yellow">
                            <span class="btn-text mr-2 ">View More</span>
                            <i class="fa-solid fa-chevron-right"></i>
                         </button>
                    </div>
                </Col>
            </Row>
        </Container>
      </section>
    </>
  )
}

export default Shareholders