import React, {useState, useEffect, useRef, useLayoutEffect} from 'react'
import { Col, Container, Nav, Row, Dropdown, Accordion  } from 'react-bootstrap';
import { useCookies } from 'react-cookie'
import {
BrowserRouter as Router,
Switch,
Route,
Link,
Routes
} from "react-router-dom";
const Header = () => {
    const [showDropdown, setShowDropdown] = useState(false)
    const [showDropdown1, setShowDropdown1] = useState(false)
    const [showDropdown2, setShowDropdown2] = useState(false)
    const [showDropdown3, setShowDropdown3] = useState(false)
    const [show1, setShow1] = useState(false)
    const handleTheme = (theme) => {
    const elememt = document.querySelector('.wp-bg')
    console.log('element ', elememt)
}
// Scroll 
const stickyHeader = useRef()
  useLayoutEffect(() => {
    const mainHeader = document.getElementById('myHeader')
    let fixedTop = stickyHeader.current.offsetTop
    const fixedHeader = () => {
      if (window.pageYOffset > fixedTop) {
        mainHeader.classList.add('sticky')
      } else {
        mainHeader.classList.remove('sticky')
      }
    }
    window.addEventListener('scroll', fixedHeader)
  }, [])

    const [headShow, setHeadShow] = useState(false)
    const Headshow = () => {
        setHeadShow(!headShow)
    }

return (
<>
<header id="myHeader" className='main-header' ref={stickyHeader}>
   <Router>
        <div className='nav-wrapper'>
            <Container fluid>
                <Nav className='navbar navbar-expand-lg navbar-light nav-new dektop-nav'>
                    <a href="/" className='navbar-brand link'>
                        <img src="https://selfservice.thestorywallcafe.com/html/selfservice/images/mtn-2022-logo-black-rgb.png" border="0" alt="MTN logo" class="pointer"/>
                    </a>  
                        <div className='collapse navbar-collapse' id="navbarTogglerDemo01">
                            <ul className='navbar-nav mr-auto mt-2 mt-lg-0' id="mega-menu">
                                <li className='nav-item'>
                                    <Dropdown onMouseLeave={() => setShowDropdown(false)}
                                        onMouseOver={() => setShowDropdown(true)}
                                        >
                                        <Dropdown.Toggle
                                            className="main-style"
                                            id="dropdown-basic"
                                          >
                                            <a href="/investor" className='nav-link'>Investors</a>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu show={showDropdown}>
                                            <a href="/investor/more-in-investors/" className='nav-link dropdown-item'>
                                              More in Investors
                                            </a>
                                            <a href="/investor/shareholders/" className='nav-link dropdown-item'>
                                              Shareholders
                                            </a>
                                            <a href="/investor/financial-reporting/" className='nav-link dropdown-item'>
                                              Finance Reporting
                                            </a>
                                            <a href="/investor/announcements/" className='nav-link dropdown-item'>
                                              Announcments
                                            </a>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </li>
                                <li className='nav-item'>
                                    <a href="/foundation/" className='nav-link'>Foundation</a>
                                </li>
                                <li className='nav-item'>
                                    <a href="/events/" className='nav-link'>Events</a>
                                </li>
                                <li className='nav-item'>
                                    <Dropdown onMouseLeave={() => setShowDropdown1(false)}
                                        onMouseOver={() => setShowDropdown1(true)}
                                        >
                                        <Dropdown.Toggle
                                            className="main-style"
                                            id="dropdown-basic1"
                                          >
                                            <Link className='nav-link'>Support</Link>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu show={showDropdown1}>
                                            <Link to="https://mtnnigeria.egain.cloud/kb/get-help/home" className='nav-link dropdown-item'>
                                              Help Center
                                            </Link>
                                            <Link to="https://community.mtnnigeria.net/" className='nav-link dropdown-item'>
                                              Community
                                            </Link>
                                            <Link to="https://www.mtn.ng/just-for-you/" className='nav-link dropdown-item'>
                                              Just For You
                                            </Link>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </li>
                                <li className='nav-item'>
                                    <Dropdown onMouseLeave={() => setShowDropdown2(false)}
                                        onMouseOver={() => setShowDropdown2(true)}
                                        >
                                        <Dropdown.Toggle
                                            className="main-style"
                                            id="dropdown-basic2"
                                          >
                                            <Link to="https://www.mtn.ng/about-us/" className='nav-link'>About Us</Link>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu show={showDropdown2}>
                                            <Link to="https://www.mtn.ng/about-us/who-we-are/our-code-of-ethics/" className='nav-link dropdown-item'>
                                              Our Code Of Ethics
                                            </Link>
                                            <Link to="https://www.mtn.ng/about-us/who-we-are/about-us/privacy-and-data-protection-policy/" className='nav-link dropdown-item'>
                                              Privacy & Data Protection
                                            </Link>
                                            <Link to="https://www.mtn.ng/about-us/who-we-are/about-us/our-story/" className='nav-link dropdown-item'>
                                              Our Story
                                            </Link>
                                            <Link to="https://www.mtn.ng/about-us/who-we-are/press-and-insights/" className='nav-link dropdown-item'>
                                              Newsroom
                                            </Link>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </li>
                                <li className='nav-item'>
                                    <Dropdown onMouseLeave={() => setShowDropdown3(false)}
                                        onMouseOver={() => setShowDropdown3(true)}
                                        >
                                        <Dropdown.Toggle
                                            className="main-style"
                                            id="dropdown-basic3"
                                          >
                                            <Link className='nav-link'>Career</Link>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu show={showDropdown3}>
                                            <Link to="https://ehle.fa.em2.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_1/requisitions" className='nav-link dropdown-item'>
                                              Apply For Jobs
                                            </Link>
                                            <Link to="https://www.mtn.ng/mtn-careers-faq/" className='nav-link dropdown-item'>
                                              Frequently Asked Questions
                                            </Link>
                                            <Link to="https://www.mtn.ng/diversity-and-inclusion-policy/" className='nav-link dropdown-item'>
                                              Diversity & Inclusion Policy
                                            </Link>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </li>
                                <li className='nav-item'>
                                    <Link to="https://www.mtn.ng/contact-us/" className='nav-link'>Contact Us</Link>
                                </li>
                            </ul>
                        </div>
                        <div className='cart-search-login-prt'>
                            <div className='search-prt new-search'>
                                <form>
                                    <span className='search-icon'>
                                        <img src='https://selfservice.thestorywallcafe.com/html/selfservice/images/icons-search.svg' />
                                    </span>
                                    <input type='search' className='form-control mr-2 nav-input' placeholder="Search....." aria-label="Search"/>
                                </form>
                            </div>
                        </div>
                </Nav>
                <Nav className="navbar navbar-light nav-new mobile-nav">
                    <Link className='navbar-brand link'>
                        <img src="https://selfservice.thestorywallcafe.com/html/selfservice/images/mtn-2022-logo-black-rgb.png" border="0" alt="MTN logo" class="pointer"/>
                    </Link>
                    <Link className={`toggle_btn ${headShow ? 'active' : ''} `} onClick={() => {Headshow()}}>
                        <i class="fa-solid fa-bars"></i>
                    </Link> 
                    <Link className={`toggle_btn_cross ${headShow ? 'active' : ''} `} onClick={() => {Headshow()}}>
                        <i class="fa-solid fa-xmark"></i>
                    </Link> 
                </Nav>
                <div className={`mob_d ${headShow ? 'active' : ''} `}>
                    <Accordion id="menu_mob_s">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                <div className="card-header">
                                    <a href="/investor/">Investors</a>
                                    <i class="fa-solid fa-minus minus"></i>
                                    <i class="fa-solid fa-plus plus"></i>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <a href="/investor/more-in-investors/" className="toglink">
                                    More in Investors
                                </a>
                                <a href="/investor/shareholders/" className="toglink">
                                    Shareholders
                                </a>
                                <a href="/investor/financial-reporting/" className="toglink">
                                    Financial Reporting
                                </a>
                                <a href="/investor/announcements/" className="toglink">
                                    Announcments
                                </a>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>
                                <div className="card-header">
                                    <a href="/foundation/">Foundation</a>
                                </div>
                            </Accordion.Header>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>
                                <div className="card-header">
                                    <Link to="https://events.mtn.ng/">Events</Link>
                                </div>
                            </Accordion.Header>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>
                                <div className="card-header">
                                    <Link to="/">Support</Link>
                                    <i class="fa-solid fa-minus minus"></i>
                                    <i class="fa-solid fa-plus plus"></i>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <Link to="https://mtnnigeria.egain.cloud/kb/get-help/home" className="toglink">
                                    Help Center
                                </Link>
                                <Link to="https://community.mtnnigeria.net/" className="toglink">
                                    Community
                                </Link>
                                <Link to="https://www.mtn.ng/just-for-you/" className="toglink">
                                    Just For You
                                </Link>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>
                                <div className="card-header">
                                    <Link to="https://www.mtn.ng/about-us/">About Us</Link>
                                    <i class="fa-solid fa-minus minus"></i>
                                    <i class="fa-solid fa-plus plus"></i>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <Link to="https://www.mtn.ng/about-us/who-we-are/our-code-of-ethics/" className="toglink">
                                    Our Code of Ethics
                                </Link>
                                <Link to="https://www.mtn.ng/about-us/who-we-are/about-us/privacy-and-data-protection-policy/" className="toglink">
                                    Privacy & Data Protection
                                </Link>
                                <Link to="https://www.mtn.ng/about-us/who-we-are/about-us/our-story/" className="toglink">
                                    Our Story
                                </Link>
                                <Link to="https://www.mtn.ng/about-us/who-we-are/press-and-insights/" className="toglink">
                                    Newsroom
                                </Link>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5">
                            <Accordion.Header>
                                <div className="card-header">
                                    <Link to="/#">Careers</Link>
                                    <i class="fa-solid fa-minus minus"></i>
                                    <i class="fa-solid fa-plus plus"></i>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <Link to="https://ehle.fa.em2.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_1/requisitions" className="toglink">
                                    Apply for Jobs
                                </Link>
                                <Link to="https://www.mtn.ng/mtn-careers-faq/" className="toglink">
                                    Frequently Asked Questions
                                </Link>
                                <Link to="https://www.mtn.ng/diversity-and-inclusion-policy/" className="toglink">
                                    Diversity & Inclusion Policy
                                </Link>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="6">
                            <Accordion.Header>
                                <div className="card-header">
                                    <Link to="https://www.mtn.ng/contact-us/">Contact Us</Link>
                                </div>
                            </Accordion.Header>
                        </Accordion.Item>
                        <Accordion.Item eventKey="7">
                            <Accordion.Header>
                                 <div className="card-header">
                                    <form>
                                        <span className="search-icon">
                                            <i class="fa-solid fa-magnifying-glass"></i>
                                        </span>
                                        <input type="search" className="form-control mr-2 nav-input" placeholder="Search" aria-label="Search"/>
                                    </form>
                                </div>
                            </Accordion.Header>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </Container>
        </div>
   </Router>
</header>
</>
)
}
export default Header