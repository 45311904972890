import React, { Fragment, useRef, useState } from 'react'
import Slider from 'react-slick'
import Shopone from "../../assets/image/projectone.png"
import Shoptwo from "../../assets/image/projecttwo.jpg"
import Shopthree from "../../assets/image/projectthree.jpeg"
import Shopfour from "../../assets/image/projectfour.jpg"
import Shopfive from "../../assets/image/projectfive.jpg"

export default function ProjectCarousel() {
	const sliderRef = useRef()
	const [sliderIndex, serSliderIndex] = useState(0)
	const settings = {
		centerMode:true,
	    slidesToShow: 3,
	    slidesToScroll: 3,
	    dots: true,
	    infinite: true,
	    cssEase: 'linear',
	    centerPadding:"120px",
	    arrows: true,

		responsive: [
			{
				breakpoint: 764,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: true,
	    			centerPadding:"30px",
	    			arrows: false,
				},
			},
		],
	}
	return (
		<Fragment>
			<Slider
				afterChange={(e) => {
					serSliderIndex(e)
				}}
				ref={sliderRef}
				arrows={null}
				{...settings}
			>
				<div className="item">
					<div className="mtn-card mtn-card--shadow mtn-card--image mtn-card--link  mtn-card--cursor">
						<a href='https://shop.mtn.ng/' className='red_link white-text'>
							<img className="personal" src={Shopone} alt="Shopone"/>
							<div className="mtn-card__content">
								<div className="mtn-tag mtn-tag--white">Youth Development</div>
								<h3 class="mtn-card__header mtn-h2 d-flex align-items-end justify-between w-100">Life in My City<br/> Arts Festival
									<a href='https://shop.mtn.ng/' className='mtn-fab-btn--mini  mtn-btn--skin-white m-0'>
										<i class="mdi fa-solid fa-chevron-right"></i>
									</a>
								</h3>
								{/* <button className="mtn-btn  mtn-btn--text-right-icon mtn-btn--skin-black">Learn More <i class="fa fa-chevron-right ml-20" aria-hidden="true"></i></button> */}
							</div>
						</a>
					</div>
				</div>{' '}
				<div className="item">
					<div className="mtn-card mtn-card--shadow mtn-card--image mtn-card--link  mtn-card--cursor">
						<a href='https://shop.mtn.ng/' className='red_link white-text'>
							<img className="personal" src={Shoptwo} alt="Shoptwo"/>
							<div className="mtn-card__content">
								<div className="mtn-tag mtn-tag--white">National Priority</div>
								<h3 class="mtn-card__header mtn-h2  d-flex align-items-end justify-between w-100">Market Doctor Project
									<a href='https://shop.mtn.ng/' className='mtn-fab-btn--mini  mtn-btn--skin-white m-0'>
										<i class="mdi fa-solid fa-chevron-right"></i>
									</a>
								</h3>
							</div>
						</a>
					</div>
				</div>{' '}			
				<div className="item">
					<div className="mtn-card mtn-card--shadow mtn-card--image mtn-card--link  mtn-card--cursor">
						<a href='https://shop.mtn.ng/' className='red_link white-text'>
							<img className="personal" src={Shopthree} alt="Shopthree"/>
							<div className="mtn-card__content">
								<div className="mtn-tag mtn-tag--white">National Priority</div>
								<h3 class="mtn-card__header mtn-h2  d-flex align-items-end justify-between w-100"> Y’ello Doctor Mobile Medical Intervention
									<a href='https://shop.mtn.ng/' className='mtn-fab-btn--mini  mtn-btn--skin-white m-0'>
										<i class="mdi fa-solid fa-chevron-right"></i>
									</a>
								</h3>
							</div>
						</a>
					</div>
				</div>{' '}			
				<div className="item">
					<div className="mtn-card mtn-card--shadow mtn-card--image mtn-card--link  mtn-card--cursor">
						<a href='https://shop.mtn.ng/' className='red_link white-text'>
							<img className="personal" src={Shopfour} alt="Shopfour"/>
							<div className="mtn-card__content">
								<div className="mtn-tag mtn-tag--white">Youth Development</div>
								<h3 class="mtn-card__header mtn-h2  d-flex align-items-end justify-between w-100"> MUSON Music Scholars Program
									<a href='https://shop.mtn.ng/' className='mtn-fab-btn--mini  mtn-btn--skin-white m-0'>
										<i class="mdi fa-solid fa-chevron-right"></i>
									</a>
								</h3>
							</div>
						</a>
					</div>
				</div>{' '}
			</Slider>
		</Fragment>
	)
}
