import React from 'react'
import JsonData from './banner.json'
 const Homebanner = () =>{
    const DisplayData=JsonData.map(
        (info)=>{
            return(
                <tr>
                    <td>{info.title}</td>
                    <td>{info.description}</td>
                    <td><img src={info.imgpath} alt='info.imgpath' className='tbimg' /></td>
                    <td>
                        <a href="javascript:void(0);" className='anc_edt'>Edit</a> 
                        <a href="javascript:void(0);" className='anc_del'>Delete</a>
                    </td>
                </tr>
            )
        }
    )
 
    return(
        <div>
            <table className='striped custom_table w-100'>
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>Description</th>
                        <th>Image</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>                
                    {DisplayData}
                </tbody>
            </table>             
        </div>
    )
 }
 
 export default Homebanner;