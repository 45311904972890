import React, {useState} from 'react'
import PlanTypeCarousel from "./PlanTypeCarousel";
import PersonalCarousel from "./PersonalCarousel";
import BusinessCarousel from "./BusinessCarousel";
import ShopCarousel from "./ShopCarousel";
import PlayCarousel from "./PlayCarousel";
import YouthCarousel from "./YouthCarousel";
import Yellow_up from "../../assets/image/yellow_up.png";
import Yellow_up_m from "../../assets/image/yellow_up_m.png";
import Yellow_sec_curve from "../../assets/image/yellow_sec_curve.png";
import Gray_up from "../../assets/image/gray_up.png";
import Gray_up_m from "../../assets/image/gray_up_m.png";
import Gray_two from "../../assets/image/gray_two.png";
import Gray_two_m from "../../assets/image/gray_two_m.png";
import Yellow_sec_curve_m from "../../assets/image/yellow_sec_curve_m.png";
import { Modal, Button } from 'react-bootstrap';
import MTNlogo from "../../assets/image/logo_block.jpg";
import Rating from '../rating/rating';

const Home = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>      
      <button id="nebula_div_btn" alt="Feedback" className="kampyle_vertical_button kampyle_right noOutline" onClick={handleShow}>
        <div className="kampyle_button"></div>
        <div className="kampyle_button-text">Feedback</div>
      </button>
      <section className="banner-home">
        <div className="outer thu-ban">
          <div id="big" className="owl-carousel owl-theme">
            <PlanTypeCarousel />
          </div>
        </div>
			</section>
      <img src={Yellow_up} alt='Yellow_up' className='yellow_up_im'/>
      <img src={Yellow_up_m} alt='Yellow_up_m' className='yellow_up_im_m'/>
      <section className="mtn-bg--yellow no-padding yellow_up_bg">
        <div className="mtn-btn-group mtn-btn-group--desktop-floating">
          <div className="container">
            <div className="row">
              <div className="col-12 col-xl-12">
                <div className="mtn-btn-group__floating">
                  <div className="mtn-btn-group__2-col d-flex justify-center">
                      <button className="mtn-btn mtn-btn--text-left-icon mtn-btn--skin-white mtn-btn--square-icon-desktop">
                       <a href='https://shop.mtn.ng/' className='red_link'>
                        <i class="fa-solid fa-cart-shopping mtn-icon mtn-icon-mymtn text-c-blue btn-icon"></i>
                        <span className="btn-text">Shop</span>
                        </a>
                      </button>
                    <button className="mtn-btn mtn-btn--text-left-icon mtn-btn--skin-white mtn-btn--square-icon-desktop">
                      <a href='https://selfservice.mtn.ng/' className='red_link'>
                        <i class="fa-solid fa-user mtn-icon mtn-icon-mymtn text-c-yellow btn-icon"></i>
                        <span className="btn-text">Personal</span>
                      </a>
                    </button>
                    <button className="mtn-btn mtn-btn--text-left-icon mtn-btn--skin-white mtn-btn--square-icon-desktop">
                      <a href='https://www.mtn.ng/business/' className='red_link'>
                        <i class="fa-solid fa-briefcase mtn-icon mtn-icon-mymtn text-c-black btn-icon"></i>                  
                        <span className="btn-text">Business</span>
                      </a>
                    </button>
                    <button className="mtn-btn mtn-btn--text-left-icon mtn-btn--skin-white mtn-btn--square-icon-desktop">
                      <a href='https://play.mtn.ng/' className='red_link'>
                        <i class="fa-solid fa-circle-play mtn-icon mtn-icon-mymtn text-c-play btn-icon"></i>
                        <span className="btn-text">Play</span>
                      </a>
                    </button>
                    <button className="mtn-btn mtn-btn--text-left-icon mtn-btn--skin-white mtn-btn--square-icon-desktop">
                      <a href='https://mpulseteen.mtn.ng/' className='red_link'>
                        <i class="fa-solid fa-users mtn-icon mtn-icon-mymtn text-c-momo btn-icon"></i>
                        <span className="btn-text">Youth</span>
                      </a>
                    </button>
                  </div>
                </div>
                <div className="mtn-btn-group__2-col--desktop">
                  <a href="https://auth.mtnonline.com/login?state=hKFo2SA4dDQtQWdfN0NiYnAtbE5YUk9hV0VuZmx1N1N2Y3kyaKFupWxvZ2luo3RpZNkgYkxsN3p1VWdYcjZVakhUbFJTX0xfTXZBckt4dnBCR3ajY2lk2SBsVENubVhwU2JNa0xGWmx2ajMzS1BoMXUxN2VXc1JuMg&client=lTCnmXpSbMkLFZlvj33KPh1u17eWsRn2&protocol=oauth2&redirect_uri=https%3A%2F%2Fselfservice.mtn.ng%2Fmtn%2Fcallback&scope=openid%20profile%20email&response_type=code&response_mode=query&nonce=cDQ5UXhDZGtZeFBjN2pnZms2SllKQXdlLmJwTklmVmk3aWcwMy1OZnJJNA%3D%3D&code_challenge=6SwQWqivnmXff8XT_jUM9lsbKyy-Ebz20ULZHgC_sMY&code_challenge_method=S256&auth0Client=eyJuYW1lIjoiYXV0aDAtcmVhY3QiLCJ2ZXJzaW9uIjoiMS4xMi4wIn0%3D" className="mtn-btn mtn-btn--text-right-icon mtn-btn--outlined outline-white text-c-black mr-12">
                    <span className="btn-text  text-c-black">Login to myMTN</span>
                    <i class="fa fa-chevron-right ml-20 text-c-black" aria-hidden="true"></i>
                  </a>
                  <a href="https://auth.mtnonline.com/login?state=hKFo2SBweFY0MHAtTnNHMmpaeDlzR1o3X1BtVTFMdjFIOF9DLaFupWxvZ2luo3RpZNkgd1A1bEV4aGJ1azcxV0lLNU9wbzFveG1sR0RrbllOSHGjY2lk2SB0V05sSkJmcXY4QjVjOXJWcml5OUVhdkVaTjN6cjQ2NQ&client=tWNlJBfqv8B5c9rVriy9EavEZN3zr465&protocol=oauth2&redirect_uri=https%3A%2F%2Fshop.mtn.ng%2Fmtnng%2Feshop%2Fcallback%2F&scope=openid%20profile%20email&response_mode=query&response_type=code&nonce=e403af9f26cfb3f0fc51af47bb937889&code_challenge=cX7N5ujTNdor3rtksfVvOHqGEL-3O0v2AjSEY8UyUss&code_challenge_method=S256&theme=" id="" className="mtn-btn  mtn-btn--text-right-icon mtn-btn--outlined  outline-white text-c-black">
                    <span className="btn-text  text-c-black">eShop</span>
                    <i class="fa fa-chevron-right ml-20 text-c-black" aria-hidden="true"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <img src={Yellow_sec_curve} alt='Yellow_sec_curve' className='Yellow_sec_curve z99'/>
      <img src={Yellow_sec_curve_m} alt='Yellow_sec_curve_m' className='Yellow_sec_curve_m z99'/>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="mtn-title mtn-title--portfolio">
                <div className="mtn-title--icon">
                  <i className="fa-solid fa-cart-shopping mtn-icon mtn-icon-mymtn-badge pad14 bg-dark_green text-c-white"></i>
                </div>
                <h4 className="mtn-title--heading">Shop</h4>
                <a href="https://shop.mtn.ng/" className="mtn-fab-btn--mini d-flex justify-center align-items-center w40px  mtn-fab-btn--outlined-green mb-0">
                  <i class="fa fa-chevron-right" aria-hidden="true"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div id="personal_slider">
          <ShopCarousel />
        </div>
      </section>
      <img src={Gray_up} alt="Gray_up" className="gray_up" />
      <img src={Gray_up_m} alt="Gray_up_m" className="gray_up_m" />
      <section className="mtn-bg--grey relative" id="svg">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="mtn-title mtn-title--portfolio">
                <div className="mtn-title--icon">
                  <i className="fa-solid fa-user mtn-icon mtn-icon-mymtn-badge pad14 bg-yellow text-c-white"></i>
                </div>
                <h4 className="mtn-title--heading">Personal</h4>
                <a href="https://selfservice.mtn.ng/" className="mtn-fab-btn--mini d-flex justify-center align-items-center w40px  mtn-fab-btn--outlined-yellow mb-0">
                  <i class="fa fa-chevron-right" aria-hidden="true"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div id="personal_slider">
          <PersonalCarousel />
        </div>
      </section>
      <img src={Gray_two} alt='Gray_two' className='gray_two' />
      <img src={Gray_two_m} alt='Gray_two_m' className='gray_two_m' />
      <section>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="mtn-title mtn-title--portfolio">
                <div className="mtn-title--icon">
                  <i className="fa-solid fa-briefcase mtn-icon mtn-icon-mymtn-badge pad14 bg-black text-c-white"></i>
                </div>
                <h4 className="mtn-title--heading">Business</h4>
                <a href="https://www.mtn.ng/business/" className="mtn-fab-btn--mini d-flex justify-center align-items-center w40px  mtn-fab-btn--outlined-black mb-0">
                  <i class="fa fa-chevron-right" aria-hidden="true"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div id="personal_slider" className='shadow_gray'>
          <BusinessCarousel />
        </div>
      </section>
      <img src={Gray_up} alt="Gray_up" className="gray_up" />
      <img src={Gray_up_m} alt="Gray_up_m" className="gray_up_m" />
      <section className="mtn-bg--grey relative" id="svg">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="mtn-title mtn-title--portfolio">
                <div className="mtn-title--icon">
                  <i className="fa-solid fa-circle-play mtn-icon mtn-icon-mymtn-badge pad14 bg-play text-c-white"></i>
                </div>
                <h4 className="mtn-title--heading">Play</h4>
                <a href="https://play.mtn.ng/" className="mtn-fab-btn--mini d-flex justify-center align-items-center w40px  mtn-fab-btn--outlined-play mb-0">
                  <i class="fa fa-chevron-right" aria-hidden="true"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div id="personal_slider">
          <PlayCarousel />
        </div>
      </section>
      <img src={Gray_two} alt='Gray_two' className='gray_two' />
      <img src={Gray_two_m} alt='Gray_two_m' className='gray_two_m' />
      <section>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="mtn-title mtn-title--portfolio">
                <div className="mtn-title--icon">
                  <i className="fa-solid fa-users mtn-icon mtn-icon-mymtn-badge pad14 bg-momo text-c-white"></i>
                </div>
                <h4 className="mtn-title--heading">Youth</h4>
                <a href="https://mpulseteen.mtn.ng/" className="mtn-fab-btn--mini d-flex justify-center align-items-center w40px  mtn-fab-btn--outlined-momo mb-0">
                  <i class="fa fa-chevron-right" aria-hidden="true"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div id="personal_slider">
          <YouthCarousel />
        </div>
      </section>
    {/* Modal */}
    <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <div className='canbnt'>
            <i className="fa-solid fa-xmark" onClick={handleClose}></i>
          </div>
          <div className='feedbackhead'>
            <img src={MTNlogo} border="0" alt="MTNlogo" className="pointer"/>
          </div>
          <form>
            <div className='form-group'>
              <label className="feedlabel">Overall, how satisfied were you with the website? <font>*</font></label>
               <div className='pb40'>
                  <Rating />
                  <span className='ratingMinLabel d-block'>Not At All Satisfied Extremely Satisfied</span>
               </div>
            </div>
            <div className='form-group'>
              <label className="feedlabel">Overall, it was easy to find the information that I needed.  <font>*</font></label>
               <div className='pb40'>
               <label for="checkone" className='checklabel'>
                    <input type='checkbox' id='checkone' className='checki'/>
                    <span>1</span>
                  </label>
                  <label for="checktwo" className='checklabel'>
                    <input type='checkbox' id='checktwo' className='checki'/>
                    <span>2</span>
                  </label>
                  <label for="checkthree" className='checklabel'>
                    <input type='checkbox' id='checkthree' className='checki'/>
                    <span>3</span>
                  </label>
                  <label for="checkfour" className='checklabel'>
                    <input type='checkbox' id='checkfour' className='checki'/>
                    <span>4</span>
                  </label>
                  <label for="checkfive" className='checklabel'>
                    <input type='checkbox' id='checkfive' className='checki'/>
                    <span>5</span>
                  </label>
                  <span className='ratingMinLabel d-block'>Do Not Agree At All
                  Completely Agree</span>
               </div>
            </div>
            <div className='form-group'>
              <div className='pb40'>
                <label className="feedlabel">What was the main purpose for your visit today?</label>
                <div className='radiosection'>
                  <div className='radiofield' for="general">
                    <input type='radio' className='radioi' id='general' name='radiose' />
                    <label for="general" className='f14'>General Information</label>
                  </div>
                  <div className='radiofield' for="self">
                    <input type='radio' className='radioi' id='self' name='radiose' />
                    <label for="self" className='f14'>Self Service</label>
                  </div>
                  <div className='radiofield' for="find">
                    <input type='radio' className='radioi' id='find' name='radiose' />
                    <label for="find" className='f14'>Find Information on MTN Products</label>
                  </div>
                  <div className='radiofield' for="career">
                    <input type='radio' className='radioi' id='career' name='radiose' />
                    <label for="career" className='f14'>Career opportunity</label>
                  </div>
                  <div className='radiofield' for="raise">
                    <input type='radio' className='radioi' id='raise' name='radiose' />
                    <label for="raise" className='f14'>Raise a complaint</label>
                  </div>
                  <div className='radiofield' for="book">
                    <input type='radio' className='radioi' id='book' name='radiose' />
                    <label for="book" className='f14'>Book an appointment</label>
                  </div>
                  <div className='radiofield' for="purchase">
                    <input type='radio' className='radioi' id='purchase' name='radiose' />
                    <label for="purchase" className='f14'>Purchase</label>
                  </div>
                  <div className='radiofield' for="other">
                    <input type='radio' className='radioi' id='other' name='radiose' />
                    <label for="other" className='f14'>Other</label>
                  </div>
                </div>
              </div>
            </div>
            <div className='form-group'>
              <label className="feedlabel">Is there anything else you would like to share with us?</label>
              <textarea className='form-control'></textarea>
            </div>
            <div className='footbtn'>
              <Button className='closeb' onClick={handleClose}>
                Close
              </Button>
              <Button className='submbtn' onClick={handleClose}>
                Submit
              </Button>
            </div>
          </form>
        </Modal.Body>
    </Modal>
    </>
  )
}

export default Home