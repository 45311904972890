
import React, { useRef, useState } from 'react'
import Slider from 'react-slick'
import Routerimg from "../../assets/image/yelloweb-banner.jpg"
import Fivegbanner from "../../assets/image/5G-banner.jpeg"
import Amazonweb from "../../assets/image/amazon-web.jpg"
import Meetingcopy from "../../assets/image/meeting-copy.jpg"

export default function PlanTypeCarousel() {
	const sliderRef = useRef()
	const [sliderIndex, serSliderIndex] = useState(0)
	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 2500,
	}

	return (
		<div>
			<Slider
				afterChange={(e) => {
					serSliderIndex(e)
				}}
				ref={sliderRef}
				arrows={null}
				{...settings}
			>
				<div className="item">
					<div className="productimg relative">
						<img className="relative productimg" src={Routerimg} alt="Routerimg"/>
						<div className="pos_abs">
							<div className="container">
								<div className="mtn-strapline">What are we doing today?</div>
								<h3 className="mtn-banner__header mtn-h1">Outdo yourself</h3>
								<button className="btn buy-now">Explore now <i class="fa fa-chevron-right ml-20" aria-hidden="true"></i></button>
							</div>
						</div>
					</div>
				</div>
				<div className="item">
					<div className="productimg relative">
						<img className="relative productimg" src={Fivegbanner} alt="Fivegbanner"/>
						<div className="pos_abs">
							<div className="container">
								<div className="mtn-strapline">Investor</div>
								<h3 className="mtn-banner__header mtn-h1">Same Day, Different Universe! <br/>MTN 5G</h3>
								<button className="btn buy-now">Explore now <i class="fa fa-chevron-right ml-20" aria-hidden="true"></i></button>
							</div>
						</div>
					</div>
				</div>
				<div className="item">
					<div className="productimg relative">
						<img className="relative productimg" src={Amazonweb} alt="Amazonweb"/>
						<div className="pos_abs">
							<div className="container">
								<div className="mtn-strapline">Prime Video Mobile Edition</div>
								<h3 className="mtn-banner__header mtn-h1">30-day free trial</h3>
								<button className="btn buy-now">Explore now <i class="fa fa-chevron-right ml-20" aria-hidden="true"></i></button>
							</div>
						</div>
					</div>
				</div>
				<div className="item">
					<div className="productimg relative">
						<img className="relative productimg" src={Fivegbanner} alt="Fivegbanner"/>
						<div className="pos_abs">
							<div className="container">
								<div className="mtn-strapline">Investor</div>
								<h3 className="mtn-banner__header mtn-h1">Same Day, Different Universe! <br/>MTN 5G</h3>
								<button className="btn buy-now">Explore now <i class="fa fa-chevron-right ml-20" aria-hidden="true"></i></button>
							</div>
						</div>
					</div>
				</div>
			</Slider>
		</div>
	)
}
