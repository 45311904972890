// import React, { useState } from "react"
import React from "react"
// import { useNavigate } from "react-router-dom";
const Login = () =>{
    //  const navigate = useNavigate();
    //  const [username, setusername] = useState("");
    //   const [password, setpassword] = useState("");
    //   const [authenticated, setauthenticated] = useState(
    //     localStorage.getItem(localStorage.getItem("authenticated") || false)
    //   );
    //   const users = [{ username: "admin@gmail.com", password: "testpassword" }];
    //   const handleSubmit = (e) => {
    //     e.preventDefault();
    //     const account = users.find((user) => user.username === username);
    //     if (account && account.password === password) {
    //       localStorage.setItem("authenticated", true);
    //       navigate("/admin/");
    //     }
    // };
    return(
        <>                                   
        <style>
            {
                `#frontpages header, #frontpages footer, #frontpages .black-layer, #frontpages .black-layer_m{
                    display:none;
                }`
            }
            {
               `body, html{
                    background:#eeeae7;
                    height:100vh;
                }` 
            }
            {
                `.wrapper{
                    height:100vh;
                    padding:40px 0px;
                }`
            }
        </style>
            <div className='wrap'>
                <div className='container' id='dashboard'>                    
                    <div className="login-content-prt mtn-self login-container login-container-play bg-col-change ">
                        <div className="row "> 
                            <div className="back-to-home">
                                <a onClick="window.history.back()">X</a>
                            </div>
                            <div className="d-none d-md-none col-lg-6 col-sm-12 d-sm-block d-lg-block col-md-12 col-lg-6 col-sm-12 login-img-wp ">
                                <div className="login-img-block self-web-left-login ">
                                    <div class="login-hd">Personalize your experience</div>
                                    <div className="wh-box-icon">
                                        <div class="wh-green-st">
                                            <img src="https://empnew.thestorywallcafe.com/auth0/verified.svg" alt="" />
                                        </div>
                                        <div class="wh-green-txt">
                                            Amazing things are waiting for you🤩
                                        </div>
                                    </div>
                                    <img src="https://empnew.thestorywallcafe.com/auth0/login-bg.webp" border="0" alt="" width="100%" />
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-6 col-sm-12 login-text-wp mt-2 ">
                                <div className="container p-2">
                                    <div className="login-screens">
                                        <div className="logreg-block">
                                            <div className="login-header">
                                                <div class="login-header-content-box">
                                                    <h6 id="loginWithOTPForSS">Login with email</h6>
                                                    {/* <form onSubmit={handleSubmit}> */}
                                                    <form>
                                                        <div className="form-group">
                                                            {/* value={username} onChange={(e) => setusername(e.target.value)} */}
                                                            <input type="email" className="form-control w95" name="Username"  placeholder="Enter Email Address" />
                                                        </div>
                                                        <div className="form-group">
                                                            {/*  onChange={(e) => setpassword(e.target.value)} */}
                                                            <input type="password" name="Password" className="form-control w95" placeholder="Enter Password" />
                                                        </div>
                                                        <div className="form-group">
                                                            {/* <input type="submit" className="buy-now px-5 mb-0 mt-3 ml-auto bnone" value="Login" /> */}
                                                            <a href="/admin/" type="submit" className="buy-now px-5 mb-0 mt-3 ml-auto bnone">Login</a>
                                                        </div>
                                                    </form>
                                                </div> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Login