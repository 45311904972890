import React from 'react'
import InvestorhomeCarousel from "./InvestorhomeCarousel";
import { Col, Container, Row } from 'react-bootstrap';
import Gray_up from "../../assets/image/gray_up.png";
import Gray_up_m from "../../assets/image/gray_up_m.png";
import { Link } from 'react-router-dom';

const Investor = () => {
  return (
    <>
      <section className="banner-home">
        <div className="outer thu-ban">
          <div id="big" className="owl-carousel owl-theme">
            <InvestorhomeCarousel />
          </div>
        </div>
			</section>
      <img src={Gray_up} alt="Gray_up" className="gray_up mtn60" />
      <img src={Gray_up_m} alt="Gray_up_m" className="gray_up_m" />
      <section className='mtn-bg--grey mnegt-27'>
        <Container>
          <h4 className='mb45 mt45'>Download latest Annual Report</h4>
          <Row>
            <Col md={4}>
              <div className='mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor myscrBox'>
                <div className='mtn-card__content'>
                  <div className='mtn-card--text-link__info'>
                    <div className='mtn-card--text-link__title'>
                      <h3 className="mtn-p">Scrip Dividend Reference Price Announcement</h3>
                    </div>
                    <p class="mtn-p mtn-text-smaller">PDF – 325 KB</p>
                  </div>
                  <a href="" className='mtn-fab-btn mtn-btn--skin-yellow'>
                    <i class="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className='myscrBox mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor'>
                <div className='mtn-card__content'>
                  <div className='mtn-card--text-link__info'>
                    <div className='mtn-card--text-link__title'>
                      <h3 className="mtn-p">FAQs on Scrip Dividend Election Plan</h3>
                    </div>
                    <p class="mtn-p mtn-text-smaller">PDF – 642 KB</p>
                  </div>
                  <a href="" className='mtn-fab-btn mtn-btn--skin-yellow'>
                    <i class="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className='myscrBox mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor'>
                <div className='mtn-card__content'>
                  <div className='mtn-card--text-link__info'>
                    <div className='mtn-card--text-link__title'>
                      <h3 className="mtn-p">MTN Nigeria Annual Report 2022</h3>
                    </div>
                    <p class="mtn-p mtn-text-smaller">PDF – 12.3 MB</p>
                  </div>
                  <a href="" className='mtn-fab-btn mtn-btn--skin-yellow'>
                    <i class="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className='myscrBox mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor'>
                <div className='mtn-card__content'>
                  <div className='mtn-card--text-link__info'>
                    <div className='mtn-card--text-link__title'>
                      <h3 className="mtn-p">FAQ on the Upcoming Allotment of Incentive Shares</h3>
                    </div>
                    <p class="mtn-p mtn-text-smaller">PDF – 556 KB</p>
                  </div>
                  <a href="" className='mtn-fab-btn mtn-btn--skin-yellow'>
                    <i class="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className='myscrBox mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor'>
                <div className='mtn-card__content'>
                  <div className='mtn-card--text-link__info'>
                    <div className='mtn-card--text-link__title'>
                      <h3 className="mtn-p">MTN Nigeria Annual Report 2021</h3>
                    </div>
                    <p class="mtn-p mtn-text-smaller">PDF – 7 MB</p>
                  </div>
                  <a href="" className='mtn-fab-btn mtn-btn--skin-yellow'>
                    <i class="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className='myscrBox mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor'>
                <div className='mtn-card__content'>
                  <div className='mtn-card--text-link__info'>
                    <div className='mtn-card--text-link__title'>
                      <h3 className="mtn-p">MTN Nigeria Annual Report 2020</h3>
                    </div>
                    <p class="mtn-p mtn-text-smaller">PDF – 11.1 MB</p>
                  </div>
                  <a href="" className='mtn-fab-btn mtn-btn--skin-yellow'>
                    <i class="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className='myscrBox mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor'>
                <div className='mtn-card__content'>
                  <div className='mtn-card--text-link__info'>
                    <div className='mtn-card--text-link__title'>
                      <h3 className="mtn-p">MTN Nigeria Sustainability Report 2020</h3>
                    </div>
                    <p class="mtn-p mtn-text-smaller">PDF – 13 MB</p>
                  </div>
                  <a href="" className='mtn-fab-btn mtn-btn--skin-yellow'>
                    <i class="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className='myscrBox mtn-card--text-link mtn-card mtn-card--shadow mtn-card--cursor'>
                <div className='mtn-card__content'>
                  <div className='mtn-card--text-link__info'>
                    <div className='mtn-card--text-link__title'>
                      <h3 className="mtn-p">MTN Nigeria Sustainability Report 2021</h3>
                    </div>
                    <p class="mtn-p mtn-text-smaller">PDF – 6 MB</p>
                  </div>
                  <a href="" className='mtn-fab-btn mtn-btn--skin-yellow'>
                    <i class="fa-solid fa-download"></i>
                  </a>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <p><b>MTN Investors</b></p>
              <p>The exciting journey with our customers, employees, shareholders and partners, has seen us evolve -from an ambitious start-up in 2001 into a market-leading communications provider.</p>
              <p>Your support enabled us to build the largest network in sub-Saharan Africa. For this we are grateful.</p>
              <p>Our success is a demonstration of the strong partnership that exists between us. We are committed to honouring that partnership through our continued efforts to expand access to mobile communications and enhance our services as we move into a
                 new era of growth together.</p>
              <p><b>MTN Today</b></p>
                  <ul className='mtn-ul'>
                        <li>Covers over 92% population</li>
                        <li>Connects over 75 million mobile subscribers</li>
                        <li>Expansive fibre network (covering over 32,000km)</li>
                        <li>Serving over 12,200 businesses including 1,114 large enterprises.</li>
                        <li>1st mobile network operator to launch 5G network in the country.</li>
                        <li>Created over 2 million direct and indirect employment through trade partners and agents</li>
                        <li>Extensive spectrum holdings</li>
                  </ul>
              <p>The future is bright. We look forward to delivering it… together.</p>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default Investor