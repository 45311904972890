import React, { Fragment, useRef, useState } from 'react'
import Slider from 'react-slick'
import Audapp from "../../assets/image/aud-app.jpg"
import Ayobaweb from "../../assets/image/ayoba-web.jpg"
import Mdundoweb from "../../assets/image/mdundo-Web.jpeg"
import Artboard from "../../assets/image/artboard.jpg"

export default function PlayCarousel() {
	const sliderRef = useRef()
	const [sliderIndex, serSliderIndex] = useState(0)
	const settings = {
		centerMode:true,
	    slidesToShow: 3,
	    slidesToScroll: 3,
	    dots: true,
	    infinite: true,
	    cssEase: 'linear',
	    centerPadding:"120px",
	    arrows: true,

		responsive: [
			{
				breakpoint: 764,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: true,
	    			centerPadding:"30px",
	    			arrows: false,
				},
			},
		],
	}
	return (
		<Fragment>
			<Slider
				afterChange={(e) => {
					serSliderIndex(e)
				}}
				ref={sliderRef}
				arrows={null}
				{...settings}
			>
				<div className="item">
					<div className="mtn-card mtn-card--shadow mtn-card--image mtn-card--link  mtn-card--cursor">
						<a href='https://play.mtn.ng/' className='z99 pos_rel'>
							<img className="personal obj_c" src={Audapp} alt="Audapp"/>
						</a>
					</div>
				</div>{' '}
				<div className="item">
					<div className="mtn-card mtn-card--shadow mtn-card--image mtn-card--link  mtn-card--cursor">
						<a href='https://play.mtn.ng/' className='z99 pos_rel'>
							<img className="personal obj_c" src={Ayobaweb} alt="Ayobaweb"/>
						</a>
					</div>
				</div>{' '}
				<div className="item">
					<div className="mtn-card mtn-card--shadow mtn-card--image mtn-card--link  mtn-card--cursor">
						<a href='https://play.mtn.ng/' className='z99 pos_rel'>
							<img className="personal obj_c" src={Mdundoweb} alt="Mdundoweb"/>
						</a>
					</div>
				</div>{' '}
				<div className="item">
					<div className="mtn-card mtn-card--shadow mtn-card--image mtn-card--link  mtn-card--cursor">
						<a href='https://play.mtn.ng/' className='z99 pos_rel'>
							<img className="personal obj_c" src={Artboard} alt="Artboard"/>
						</a>
					</div>
				</div>{' '}
			</Slider>
		</Fragment>
	)
}
