import React from 'react'
import BannerCarousel from "./BannerCarousel";
import Yellow_up from "../../assets/image/yellow_up.png";
import Yellow_up_m from "../../assets/image/yellow_up_m.png";
import ProjectCarousel from "./ProjectCarousel";
import Gray_up from "../../assets/image/gray_up.png";
import Gray_up_m from "../../assets/image/gray_up_m.png";
import Gray_two from "../../assets/image/gray_two.png";
import Gray_two_m from "../../assets/image/gray_two_m.png";
import { Col, Container, Row } from 'react-bootstrap';
import Testimonial from "./Testimonial";

const Foundation = () => {
  return (
    <>
      <section className="banner-home">
        <div className="outer thu-ban">
          <div id="big" className="owl-carousel owl-theme">
            <BannerCarousel />
          </div>
        </div>
	    </section>
      <img src={Yellow_up} alt='Yellow_up' className='yellow_up_im'/>
      <img src={Yellow_up_m} alt='Yellow_up_m' className='yellow_up_im_m'/>
      <section className="mtn-bg--yellow no-padding yellow_up_bg">
        <div className="mtn-btn-group mtn-btn-group--desktop-floating">
          <div className="container">
            <div className="row">
              <div className="col-12 col-xl-12">
                <div className="mtn-btn-group__floating">
                  <div className="mtn-btn-group__2-col d-flex justify-center">
                    <button className="mtn-btn mtn-btn--text-left-icon mtn-btn--skin-white mtn-btn--square-icon-desktop">
                      <a href='#' className='red_link'>
                        <i class="fa-solid fa-briefcase mtn-icon mtn-icon-mymtn text-c-black btn-icon"></i>                  
                        <span className="btn-text">About Us</span>
                      </a>
                    </button>
                    <button className="mtn-btn mtn-btn--text-left-icon mtn-btn--skin-white mtn-btn--square-icon-desktop">
                      <a href='#' className='red_link'>
                        <i class="fa-solid fa-file mtn-icon mtn-icon-mymtn text-c-black btn-icon"></i>                  
                        <span className="btn-text">Reports</span>
                      </a>
                    </button>
                    <button className="mtn-btn mtn-btn--text-left-icon mtn-btn--skin-white mtn-btn--square-icon-desktop">
                      <a href='#' className='red_link'>
                        <i class="fa-solid fa-newspaper mtn-icon mtn-icon-mymtn text-c-black btn-icon"></i>
                        <span className="btn-text">News</span>
                      </a>
                    </button>
                    <button className="mtn-btn mtn-btn--text-left-icon mtn-btn--skin-white mtn-btn--square-icon-desktop">
                      <a href='#' className='red_link'>
                        <i class="fa-solid fa-phone-volume mtn-icon mtn-icon-mymtn text-c-black btn-icon"></i>
                        <span className="btn-text">Contact Us</span>
                      </a>
                    </button>
                  </div>
                </div>
                <div className="mtn-btn-group__2-col--desktop">
                  <button id="" className="mtn-btn mtn-btn--text-right-icon mtn-btn--outlined outline-white text-c-black mr-12">
                    <span className="btn-text  text-c-black">Our Portfolio</span>
                    <i class="fa fa-chevron-right ml-20 text-c-black" aria-hidden="true"></i>
                  </button>
                  <button id="" className="mtn-btn  mtn-btn--text-right-icon mtn-btn--outlined  outline-white text-c-black mr-12">
                    <span className="btn-text  text-c-black">Our Projects</span>
                    <i class="fa fa-chevron-right ml-20 text-c-black" aria-hidden="true"></i>
                  </button>
                  <button id="" className="mtn-btn  mtn-btn--text-right-icon mtn-btn--outlined  outline-white text-c-black">
                    <span className="btn-text  text-c-black">Foundation Map</span>
                    <i class="fa fa-chevron-right ml-20 text-c-black" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <section>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="mtn-title mtn-title--portfolio">
                <h4 className="mtn-title--heading">Our Portfolio</h4>
              </div>
            </div>
          </div>
        </div>
        <div id="personal_slider" className='shadow_gray'>
          
        </div>
      </section>
      <img src={Gray_up} alt="Gray_up" className="gray_up" />
      <img src={Gray_up_m} alt="Gray_up_m" className="gray_up_m" />
      <section className="mtn-bg--grey relative" id="svg">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="mtn-title mtn-title--portfolio">
                <h4 className="mtn-title--heading">MTN Foundation Projects</h4>
                <a href="https://selfservice.mtn.ng/" className="mtn-fab-btn--mini d-flex justify-center align-items-center w40px d-inline mtn-fab-btn--outlined-yellow mb-0">
                  <i class="fa fa-chevron-right" aria-hidden="true"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div id="personal_slider" className='shadow_gray'>
          <ProjectCarousel />
        </div>
      </section>
      <img src={Gray_two} alt='Gray_two' className='gray_two' />
      <img src={Gray_two_m} alt='Gray_two_m' className='gray_two_m' />
      <section>
        <Container>
            <Row>
                <Col md={12}>
                    <div className='mtn-title mtn-title--portfolio'>
                        <h4 className="mtn-title--heading mb40">Our Impact</h4>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={3} sm={6} xs={12}>
                    <div class="mtn-card mtn-card--tiny mtn-card--tiny--top-bottom-text mtn-card--shadow mtn-card--tiny--yellow">
                        <div className='mtn-card__content text-center'>
                            <div className='mtn-card__icon-container'>
                                <i className="fa-solid fa-hand-holding-dollar mtn-icon mtn-icon--yellow btn-icon f102"></i>
                            </div>
                            <span className="mtn-card__label mtn-p mtn-text-bold f45">₦25.7B+</span>
                            <span className="mtn-card__label mtn-p">Invested in projects in the 36 state and FCT</span>
                        </div>
                    </div>
                </Col>
                <Col md={3} sm={6} xs={12}>
                    <div class="mtn-card mtn-card--tiny mtn-card--tiny--top-bottom-text mtn-card--shadow mtn-card--tiny--yellow">
                        <div className='mtn-card__content text-center'>
                            <div className='mtn-card__icon-container'>
                                <i class="fa-solid fa-heart mtn-icon mtn-icon--yellow btn-icon f102"></i> 
                            </div>
                            <span className="mtn-card__label mtn-p mtn-text-bold f45">1,016</span>
                            <span className="mtn-card__label mtn-p">Project sites implemented across Nigeria</span>
                        </div>
                    </div>
                </Col>
                <Col md={3} sm={6} xs={12}>
                    <div class="mtn-card mtn-card--tiny mtn-card--tiny--top-bottom-text mtn-card--shadow mtn-card--tiny--yellow">
                        <div className='mtn-card__content text-center'>
                            <div className='mtn-card__icon-container'>
                              <i class="fa-solid fa-location-dot  mtn-icon mtn-icon--yellow btn-icon f102"></i> 
                            </div>
                            <span className="mtn-card__label mtn-p mtn-text-bold f45">3251</span>
                            <span className="mtn-card__label mtn-p">Communities Reached across 36 state and FCT in Nigeria</span>
                        </div>
                    </div>
                </Col>
                <Col md={3} sm={6} xs={12}>
                    <div class="mtn-card mtn-card--tiny mtn-card--tiny--top-bottom-text mtn-card--shadow mtn-card--tiny--yellow">
                        <div className='mtn-card__content text-center'>
                            <div className='mtn-card__icon-container'>
                            <i class="fa-solid fa-user-group mtn-icon mtn-icon--yellow btn-icon f102"></i> 
                            </div>
                            <span className="mtn-card__label mtn-p mtn-text-bold f45">31.2M+</span>
                            <span className="mtn-card__label mtn-p">People reached through MTN Foundation interventions from  inception till date.</span>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
      </section>
      <img src={Gray_up} alt="Gray_up" className="gray_up" />
      <img src={Gray_up_m} alt="Gray_up_m" className="gray_up_m" />
      <section className="mtn-bg--grey relative" id="svg">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="mtn-title mtn-title--portfolio">
                <h4 className="mtn-title--heading">Testimonial</h4>
              </div>
            </div>
          </div>
        </div>
        <div id="testi" className='test gtco-testimonials'>
          <Testimonial />
        </div>
      </section>
      <img src={Gray_two} alt='Gray_two' className='gray_two' />
      <img src={Gray_two_m} alt='Gray_two_m' className='gray_two_m' />
    </>
  )
}

export default Foundation