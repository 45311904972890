import React, { useState } from "react";
import Adminsidebar from "../admincommen/sidebar";
import Adminheader from "../admincommen/header";
import "../../../assets/css/admin.css"
import AdminFooter from "../admincommen/footer";
import { Tab, Tabs } from 'react-bootstrap';
import Homebanner from "./home-banner";
import Bannerform from "./banner-form";
import Shop from "./shop-slider";
import Shopform from "./shop-form";
import Personal from "./personal-slider";
import Personalform from "./personal-form";
import Business from "./business-slider";
import Businessform from "./business-form";
import Play from "./play-slider";
import Playform from "./play-form";
import Youth from "./youth-slider";
import Youthform from "./youth-form";



const Adminhome = () =>{
    const [key, setKey] = useState('banner');  
    const [step, setClassStep] = useState(false); 
    const removeActive = () => {
        setClassStep(!step); 
    }
    const [step1, setClassStep1] = useState(true); 
    const addactbtn = () => {
        setClassStep1(!step1); 
    }

    const [shop, setClassShop] = useState(true); 
    const shopbtnremove = () => {
        setClassShop(!shop); 
    }
    const [shop1, setClassshop1] = useState(false); 
    const shopbtnadd = () => {
        setClassshop1(!shop1); 
    }

    const [personal, setClassPersonal] = useState(true); 
    const personalremove = () => {
        setClassPersonal(!personal); 
    }
    const [personal1, setClassPersonal1] = useState(false); 
    const personalbtn = () => {
        setClassPersonal1(!personal1); 
    }

    const [business, setClassBusiness] = useState(true); 
    const businessremove = () => {
        setClassBusiness(!business); 
    }
    const [businessl1, setClassBusiness1] = useState(false); 
    const businessbtn = () => {
        setClassBusiness1(!businessl1); 
    }

    const [play, setClassPLay] = useState(true); 
    const playremove = () => {
        setClassPLay(!play); 
    }
    const [play1, setClassPlay1] = useState(false); 
    const playbtn = () => {
        setClassPlay1(!play1); 
    }

    const [youth, setClassYouth] = useState(true); 
    const youthremove = () => {
        setClassYouth(!youth); 
    }
    const [youth1, setClassYouth1] = useState(false); 
    const youthbtn = () => {
        setClassYouth1(!youth1); 
    }
    return (
        <>
        <Adminheader />
        <Adminsidebar/>
            <div className='wrap'>
				<div className='content_wrap' id='dashboard'>
					<h5 class="bundle-type-heading"><b>Home Setting</b></h5>
                    <div className="dashboard-full-wrap send-question-lect-content custom_tabs">
                    <Tabs 
                        id="controlled-tab-send-question"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        >
                        <Tab eventKey='banner' title='Banner Slide'>
                            <div role="tabpanel" class="tab-pane active" id="banner">
                                <div className="d-flex align-items-center justify-between">
                                    <h4 className="f15">Home Banner Setting</h4>
                                    <a href="javascript:void(0);" className=" buy-now" id="bnone" onClick={()=>{addactbtn(); removeActive();}}>Add New Banner</a>
                                </div>
                                <div className={`banner_sec ${step1 ? "active" : "" }`}>
                                    <Homebanner/>
                                </div>
                                <div className={`banner_for_sec ${step ? "active" : "" }`}>
                                    <Bannerform/>
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey='shop' title='Shop'>
                            <div role="tabpanel" class="tab-pane" id="shop">
                                <div className="d-flex align-items-center justify-between">
                                    <h4 className="f15">Shop Slider Setting</h4>
                                    <a href="javascript:void(0);" className=" buy-now" id="bnone" onClick={()=>{shopbtnadd(); shopbtnremove();}}>Add New Shop</a>
                                </div>
                                <div className={`banner_sec ${shop ? "active" : "" }`}>
                                    <Shop/>
                                </div>
                                <div className={`banner_for_sec ${shop1 ? "active" : "" }`}>
                                    <Shopform/>
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey='personal' title='Personal'>
                            <div role="tabpanel" class="tab-pane " id="personal">
                                <div className="d-flex align-items-center justify-between">
                                    <h4 className="f15">Personal Slider Setting</h4>
                                    <a href="javascript:void(0);" className=" buy-now" id="bnone" onClick={()=>{personalbtn(); personalremove();}}>Add New Personal</a>
                                </div>
                                <div className={`banner_sec ${personal ? "active" : "" }`}>
                                    <Personal/>
                                </div>
                                <div className={`banner_for_sec per_img ${personal1 ? "active" : "" }`}>
                                    <Personalform />
                                </div>
                            </div>
                        </Tab> 
                        <Tab eventKey='business' title='Business'>
                            <div role="tabpanel" class="tab-pane" id="business">
                                <div className="d-flex align-items-center justify-between">
                                    <h4 className="f15">Business Slider Setting</h4>
                                    <a href="javascript:void(0);" className=" buy-now" id="bnone" onClick={()=>{businessbtn(); businessremove();}}>Add New Business</a>
                                </div>
                                <div className={`banner_sec ${business ? "active" : "" }`}>
                                    <Business />
                                </div>
                                <div className={`banner_for_sec per_img ${businessl1 ? "active" : "" }`}>
                                    <Businessform />
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey='play' title='Play'>
                            <div role="tabpanel" class="tab-pane" id="play">
                                <div className="d-flex align-items-center justify-between">
                                    <h4 className="f15">Play Slider Setting</h4>
                                    <a href="javascript:void(0);" className=" buy-now" id="bnone" onClick={()=>{playbtn(); playremove();}}>Add New Play</a>
                                </div>
                                <div className={`banner_sec ${play ? "active" : "" }`}>
                                    <Play/>
                                </div>
                                <div className={`banner_for_sec per_img ${play1 ? "active" : "" }`}>
                                    <Playform/>
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey='youth' title='Youth'>
                        <div role="tabpanel" class="tab-pane" id="youth">
                                <div className="d-flex align-items-center justify-between">
                                    <h4 className="f15">Youth Slider Setting</h4>
                                    <a href="javascript:void(0);" className=" buy-now" id="bnone" onClick={()=>{youthbtn(); youthremove();}}>Add New Youth</a>
                                </div>
                                <div className={`banner_sec ${youth ? "active" : "" }`}>
                                    <Youth/>
                                </div>
                                <div className={`banner_for_sec per_img ${youth1 ? "active" : "" }`}>
                                    <Youthform/>
                                </div>
                            </div>
                        </Tab>
                    </Tabs>
                    </div>
                </div>
            </div>	
        <AdminFooter/>
        </>
    )
}
export default Adminhome