import React, { useState } from "react"
import { Col, Row } from "react-bootstrap"
import Adminsidebar from "../admincommen/sidebar";
import Adminheader from "../admincommen/header";
import AdminFooter from "../admincommen/footer";

const Settings = () =>{
    const [file, setFile] = useState("https://unper.ac.id/wp-content/plugins/tutor/assets/images/placeholder-.jpg");
    function handleChange(e) {
        console.log(e.target.files);
        setFile(URL.createObjectURL(e.target.files[0]));
    }
    return(
        <>
        <Adminheader />
        <Adminsidebar/>
        <div className='wrap'>
            <div className='content_wrap' id='dashboard'>
                <h5 class="bundle-type-heading"><b>Setting</b></h5>
                <div className="form_section">
                    <form>
                        <Row>
                            <Col md={6} xs={12}>
                                <div className="form-group">
                                    <label className="mb-1">Site Title</label>
                                    <input className="form-control" type="text" placeholder="Enter Title" />
                                </div>
                            </Col>
                            <Col md={6} xs={12}>
                                <div className="form-group">
                                    <label className="mb-1">Slogan</label>
                                    <input className="form-control" type="text" placeholder="Enter Title" />
                                </div>
                            </Col>
                            <Col md={6} xs={12}>
                                <div className="form-group mt-3">
                                    <label className="mb-1">logo</label>
                                    <div className="multiupload active">
                                        <div className="dotted_bor mt-0" id="bnone">
                                            <div className="images_area" id="flexnone">
                                                <div className="upload_file">
                                                    <img src={file} className="dropimg" />
                                                    <div className="image_upload_botton">
                                                        <input type="file" className="file_i" onChange={handleChange}/>
                                                        <span className="">Select Image</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <small>Maximum upload file size: 40 MB.</small>
                                    </div>
                                </div>
                            </Col>
                            <Col md={6} xs={12}>
                                <div className="form-group mt-3">
                                    <label className="mb-1">Fevicon</label>
                                    <div className="multiupload active">
                                        <div className="dotted_bor mt-0" id="bnone">
                                            <div className="images_area" id="flexnone">
                                                <div className="upload_file">
                                                    <img src={file} className="dropimg" />
                                                    <div className="image_upload_botton">
                                                        <input type="file" className="file_i" onChange={handleChange}/>
                                                        <span className="">Select Image</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <small>Maximum upload file size: 40 MB.</small>
                                    </div>
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className="text-right mt-3">
                                    <button className="cancelbtn px-5" id="bnone">Cancel</button>
                                    <button className="buy-now px-5 mb-0" id="bnone">Save</button>
                                </div>
                            </Col>
                        </Row>
                    </form>
                </div>
            </div>
        </div>   
        <AdminFooter/>
        </>
    )
}
export default Settings