import React, { useEffect, useState } from 'react'
import {Accordion } from "react-bootstrap";
import Adminsidebar from "../admincommen/sidebar";
import Adminheader from "../admincommen/header";
import "../../../assets/css/admin.css"
import AdminFooter from "../admincommen/footer";
import { Navigate } from "react-router-dom";
import Users from './users';
const DashboardAdmin = () => {
	// const [authenticated, setauthenticated] = useState(null);
	//   useEffect(() => {
	//     const loggedInUser = localStorage.getItem("authenticated");
	//     if (loggedInUser) {
	//       setauthenticated(loggedInUser);
	//     }
	//   }, []);
	// if (!authenticated) {
	// 	  return <Navigate replace to="/admin/" />;
	// 	} else {	
	return (
		<>
            <Adminheader />
            <Adminsidebar/>
			<div className='wrap'>
				<div className='content_wrap' id='dashboard'>
					<h5 class="bundle-type-heading"><b>Dashboard</b></h5>	
					<div className='dashboard-widgets-wrap'>
						<div className='dashboard_widget_box'>
							<div id="myaccourdion" className="accordion">
								<Accordion defaultActiveKey="0">
									<Accordion.Item eventKey="0">
										<Accordion.Header>
                                            <div className="card-header">
                                                <a className="card-title">Site Health Status</a>
                                                <i class="fa fa-chevron-up up_a" aria-hidden="true"></i>
                                                <i class="fa fa-chevron-down down_a" aria-hidden="true"></i>
                                            </div>
                                        </Accordion.Header>
											<Accordion.Body>
                                                <div className="card-body">
													<div className='d-flex align-items-center'>
														<div className='w34 text-center'>
															<div class="site-health-progress">
																<svg aria-hidden="true" focusable="false" width="100%" height="100%" viewBox="0 0 200 200" version="1.1" xmlns="http://www.w3.org/2000/svg">
																	<circle r="90" cx="100" cy="100" fill="transparent" stroke-dasharray="565.48" stroke-dashoffset="0"></circle>
																	<circle id="bar" r="90" cx="100" cy="100" fill="transparent" stroke-dasharray="565.48" stroke-dashoffset="0"></circle>
																</svg>
															</div>
															<p><b>Should be improved</b></p>
														</div>
														<div className='w66'>
															<p>
																Your site has critical issues that should be addressed as soon as possible to improve its performance and security.
															</p>
															<p>
																Take a look at the <b> 8 items </b> on the <a href='javascript:void(0)' className='underline'>Site Health screen.</a>
															</p>
														</div>
													</div>
                                                </div>
                                            </Accordion.Body>
									</Accordion.Item>
								</Accordion>
							</div>
						</div>
						<div className='dashboard_widget_box'>
							<div id="myaccourdion" className="accordion">
								<Accordion defaultActiveKey="2">
									<Accordion.Item eventKey="2">
										<Accordion.Header>
                                            <div className="card-header">
                                                <a className="card-title">Activity</a>
                                                <i class="fa fa-chevron-up up_a" aria-hidden="true"></i>
                                                <i class="fa fa-chevron-down down_a" aria-hidden="true"></i>
                                            </div>
                                        </Accordion.Header>
											<p className='m-0 pad_para'><b>Recent Comments</b></p>
											<Accordion.Body>
                                                <div className="card-body page-title">
                                                    <div className='d-flex align-items-center'>
														<div className='w20'>
															<img src='https://selfservice.thestorywallcafe.com/html/selfservice/images/mtn-2022-logo-black-rgb.png' className='logo_pn' />
														</div>
														<div className='w75'>
															<p className='mb-2'>From <a href=''> Selfservice Commenter </a> on <a href=''> Hello world! </a><br/>
															Hi, this is a comment. To get started with moderating, editing, and deleting comments, please visit the Comments screen in…</p>
															<div className="hover_data_table">
																<a href="javascript:void(0)" className="link_trash devider_r ml-0">Unapprove</a>
																<a href="javascript:void(0)" className="link_edit devider_r">Reply</a>
																<a href="/admin/comments/edit" className="link_edit devider_r">Edit</a>
																<a href="javascript:void(0);" className="link_edit devider_r">View</a>
																<a href="#" className="link_trash devider_r">Span</a>
																<a href="#" className="link_trash">Trash</a>
															</div>
														</div>
													</div>
                                                </div>
                                            </Accordion.Body>
											<ul class="subsubsub pad_para1 m-0">
												<li class="all f15 text_gray">
													<a href="#" class="current blacktext" aria-current="page">All <span class="count text_gray">(1)</span></a> |
												</li>
												<li class="publish pr4">
													<a href="#" className='blacktext'>Mine <span class="count text_gray">(0)</span></a> |
												</li>
												<li class="publish pr4">
													<a href="#" className='blacktext'>Pending <span class="count text_gray">(0)</span></a> |
												</li>
												<li class="publish pr4">
													<a href="#" className='blacktext'>Approved <span class="count text_gray">(1)</span></a> |
												</li>
												<li class="publish pr4">
													<a href="#" className='blacktext'>Span <span class="count text_gray">(0)</span></a> |
												</li>
												<li class="publish pr4">
													<a href="#" className='blacktext'>Trash <span class="count text_gray">(0)</span></a>
												</li>
											</ul>
									</Accordion.Item>
								</Accordion>
							</div>							
						</div>
					</div>	
                    {/* Full view Section */}
                    <div className='dashboard-full-wrap'>
                            <div id="myaccourdion" className="accordion">
								<Accordion defaultActiveKey="3">
									<Accordion.Item eventKey="3">
										<Accordion.Header>
                                            <div className="card-header">
                                                <a className="card-title">Users</a>
                                                <i class="fa fa-chevron-up up_a" aria-hidden="true"></i>
                                                <i class="fa fa-chevron-down down_a" aria-hidden="true"></i>
                                            </div>
                                        </Accordion.Header>
											<Accordion.Body>
                                                <div className="card-body ">
													<Users />
												</div>
                                            </Accordion.Body>
									</Accordion.Item>
								</Accordion>
							</div>
                    </div>
				</div>
			</div>
            <AdminFooter/>
		</>
	)
		}

export default DashboardAdmin
