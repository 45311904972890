import React, {useState} from "react"
import { Col, Row, Modal, Button } from "react-bootstrap"
import AdminHeader from "../admincommen/header"
import Adminsidebar from "../admincommen/sidebar"
import AdminFooter from "../admincommen/footer"
const Profile = () =>{
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [file, setFile] = useState("https://unper.ac.id/wp-content/plugins/tutor/assets/images/placeholder-.jpg");
    function handleChange(e) {
        console.log(e.target.files);
        setFile(URL.createObjectURL(e.target.files[0]));
    }
    return(
        <>
        <AdminHeader />
        <Adminsidebar/>
            <div className='wrap'>
                <div className='content_wrap' id='dashboard'>
                    <h5 class="bundle-type-heading"><b>Profile</b></h5>
                    <Row>
                        <Col md={4}>
                            <div className="card_box" id="pad0">
                                <div className="dotted_bor mt-0" id="bnone">
                                    <div className="images_area" id="flexnone">
                                        <div className="upload_file">
                                            <img src={file} className="dropimg" id="dropi"/>
                                            <div className="image_upload_botton" id="no_rad">
                                                <input type="file" className="file_i" onChange={handleChange}/>
                                                <span className="" >Edit Profile</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={8}>
                            <div className="card_box">
                                <div className="flex align-items-center justify-between bb mb-3">
                                    <h3 className="f15">General Information</h3>
                                    <a href="javascript:void(0);" className="" onClick={handleShow}>Edit Information</a>
                                </div>
                                <ul className="profile_ui">
                                    <li><span className="stitle">Name</span>: Howdy</li>
                                    <li><span className="stitle">Role</span>: Admin</li>
                                    <li><span className="stitle">Email ID</span>: howdy.admin@gmail.com</li>
                                    <li><span className="stitle">Phone</span>: +44 987 234 8765</li>
                                </ul>
                                <h3 className="f15 mt-3 bb pb-1 mb-3">Change Password</h3>
                                <form className="paschange">
                                    <Row>
                                        <Col md={12}>
                                            <div className="form-group">
                                                <label className="mb-1">Old Password</label>
                                                <input type="password" className="form-control" placeholder="********"/>
                                            </div>
                                         </Col>
                                         <Col md={6}>
                                            <div className="form-group mt-2">
                                                <label className="mb-1">New Password</label>
                                                <input type="password" className="form-control" placeholder="********"/>
                                            </div>  
                                        </Col> 
                                        <Col md={6}>                                
                                            <div className="form-group mt-2">
                                                <label className="mb-1">Confirm Password</label>
                                                <input type="password" className="form-control" placeholder="********"/>
                                            </div>
                                        </Col> 
                                        <Col md={12} className="text-right">
                                            <button class="buy-now px-5 mb-0 mt-3 ml-auto" id="bnone">Submit</button>
                                        </Col>
                                    </Row>
                                </form>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        <AdminFooter/>
        <Modal show={show} onHide={handleClose} size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
        <form>
            <Modal.Header closeButton>
            <Modal.Title className="f15"><b>General Information</b></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="form-group">
                    <label>Full Name</label>
                    <input type="text" className="form-control" value="Howdy" placeholder="Enter full name" />
                </div>
                <div className="form-group mt-3">
                    <label>Role</label>
                    <select className="form-control" >
                        <option>Admin</option>
                        <option>User</option>
                    </select>
                </div>
                <div className="form-group mt-3">
                    <label>Email ID</label>
                    <input type="email" className="form-control" value="howdy.admin@gmail.com" placeholder="Enter your email id" />
                </div>
                <div className="form-group mt-3">
                    <label>Phone</label>
                    <input type="text" className="form-control" value="+44 987 234 8765" placeholder="Enter your email id" />
                </div>
            </Modal.Body>
            <Modal.Footer>
            <Button className="buy-now px-5 mb-0 bg_white cl_yellow bb_yellow mr16" onClick={handleClose}>
                Close
            </Button>
            <Button className="buy-now px-5 mb-0 ml-auto bb_yellow" onClick={handleClose}>
                Save Changes
            </Button>
            </Modal.Footer>
        </form>
      </Modal>
        </>
    )
}
export default Profile